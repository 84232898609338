<div class="dialog-container">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
        <p [innerHTML]="data.question"></p>
    </div>
    <mat-form-field *ngIf="data.reasons$">
        <mat-label>{{ 'OVERVIEW.DIALOG.DELETE_OFFER.REASON' | translate }}</mat-label>
        <mat-select [(value)]="reason">
            <mat-option *ngFor="let available of availableReasons$ | async" [value]="available">
                {{ available.displayValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-button [mat-dialog-close]="false">{{ data.no }}</button>
        <button
            mat-raised-button
            color="primary"
            [mat-dialog-close]="reason?.value || true"
            [disabled]="data.reasons$ && !reason"
        >
            {{ data.yes }}
        </button>
    </div>
</div>
