import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NextStepsDataService, PrintOfferPayload } from 'src/app/next-steps/next-steps.data.service';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { PermissionService } from '../../services/permission.service';
import { QuoteService } from '../../services/quote.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UrlService } from '../../services/url.service';
import {
    SendDocumentSettings,
    SendDocumentsViaEmailDialogComponent,
} from '../send-documents-via-email-dialog/send-documents-via-email-dialog.component';
import { SelectedCustomerService } from '../../services/selected-customer.service';

export interface PdfDownloadUrl {
    isError: boolean;
    url: string;
}
export interface SchemePdf {
    id: string;
    url: string;
}

export interface UploadFileControl {
    file: UploadFile;
    control: FormControl;
    uploading: any;
    queued?: boolean;
    deleting?: boolean;
    owner?: string;
}

export interface UploadAttachmentControl {
    id: number;
    documentUri: string;
    filename: string;
}

const MAX_FILE_SIZE = 10485760;

enum SchemeType {
    scheme = 'scheme',
    dwt = 'dwt',
}

export interface Recipient {
    email: string;
}

@Component({
    selector: 'app-documents-selection',
    templateUrl: './documents-selection.component.html',
    styleUrls: ['./documents-selection.component.scss'],
})
export class DocumentsSelectionComponent implements OnInit, OnDestroy {
    private leftColumnDocumentsConfig = {
        '0500': ['QUOTE', 'SCHEME', 'ENERGY_LABEL', 'UGL', 'GAEB_D81', 'GAEB_D83', 'GAEB_X94'],
        5000: ['QUOTE', 'SCHEME', 'ENERGY_LABEL', 'UGL', 'GAEB_D81', 'GAEB_D83', 'GAEB_X94'],
        6000: ['QUOTE', 'ENERGY_LABEL'],
        '0600': ['QUOTE', 'ENERGY_LABEL'],
        default: ['QUOTE', 'ENERGY_LABEL', 'UGL', 'GAEB_D81', 'GAEB_D83', 'GAEB_X94'],
    };
    private unsubscribe$: Subject<void> = new Subject<void>();
    private noRelevanceKey = 'energylabelnotrelevant';

    public uglSelected = false;
    public gaebD81Selected = false;
    public gaebD83Selected = false;
    public gaebX94Selected = false;
    public energyLabelSelected = false;
    public schemeForm: FormGroup = new FormGroup({});
    public attachmentForm: FormGroup = new FormGroup({});
    public schemeIds: {
        primary: string[];
        secondary: string[];
    };
    public energyLabelHash: string;

    public quotePdfDownload: PdfDownloadUrl;
    public energyLabelPdfDownload: PdfDownloadUrl;
    public schemeTypes: SchemeType[] = [SchemeType.scheme, SchemeType.dwt];

    @Input()
    public sapDocumentId: string;
    @Input()
    public documentId: string;
    @Input()
    public latestRevision: string;
    @Input()
    public externalApp: boolean;
    @Input()
    public printType: string;

    options: UploaderOptions = {
        allowedContentTypes: ['application/pdf'],
        concurrency: 3,
    };

    uploadFileControls: UploadFileControl[] = [];
    uploadAttachments = [];
    uploadAttachmentsIds = [];
    uploadAttachmentsSelected: UploadAttachmentControl[] = [];
    uploadAttachmentsSelectedUris = [];
    uploading = false;

    @Output() startUpload: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

    @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;

    dragging: boolean;

    private mailText$ = new BehaviorSubject<string | null>(null);

    public recipients: string[] = [];

    get shouldDisableButton() {
        return !this.quotePdfDownload || (!this.quotePdfDownload.url && !this.quotePdfDownload.isError);
    }

    get energyLabelNotRelevant() {
        return !!(
            this.energyLabelPdfDownload &&
            !this.energyLabelPdfDownload.isError &&
            !this.energyLabelPdfDownload.url
        );
    }

    get disableEnergyLabelCheckbox() {
        return (
            this.energyLabelNotRelevant ||
            !this.energyLabelPdfDownload ||
            this.energyLabelPdfDownload.isError ||
            !this.energyLabelPdfDownload.url
        );
    }

    get leftConfig() {
        return this.leftColumnDocumentsConfig[this.appService.getSalesOrg()] || this.leftColumnDocumentsConfig.default;
    }

    constructor(
        private dataService: NextStepsDataService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private urlService: UrlService,
        private quoteService: QuoteService,
        private permissionService: PermissionService,
        public dialog: MatDialog,
        public appService: AppService,
        public auth: AuthService,
        private selectedCustomerService: SelectedCustomerService
    ) {}

    public ngOnInit() {
        this.getQuote();
        this.fetchPrintOfferPdf();
        this.fetchQuoteEmailText();
        this.refreshFileList();
        this.selectedCustomerService.selectedCustomer$
            .pipe(switchMap((customer) => (customer ? this.fetchEmailRecipients(customer.number) : of([]))))
            .subscribe((emails: string[]) => {
                this.recipients = emails.sort((a, b) => a.localeCompare(b));
            });
    }

    public sendMail() {
        this.mailText$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((text) => text !== null)
            )
            .subscribe((data) => {
                this.dialog.open<SendDocumentsViaEmailDialogComponent, SendDocumentSettings>(
                    SendDocumentsViaEmailDialogComponent,
                    {
                        data: {
                            uglSelected: this.uglSelected,
                            gaebD81Selected: this.gaebD81Selected,
                            gaebD83Selected: this.gaebD83Selected,
                            gaebX94Selected: this.gaebX94Selected,
                            energyLabelSelected: this.energyLabelSelected,
                            energyLabel: this.energyLabelHash,
                            schemes: this.getSelectedSchemes(),
                            schemesDwt: this.getSelectedSchemes(SchemeType.dwt),
                            documentId: this.documentId,
                            latestRevision: this.latestRevision,
                            offerAttachments: this.getSelectedAttachments(false),
                            mailText: data,
                            mailRecipients: this.recipients,
                        },
                    }
                );
            });
    }

    public downloadSelectionUrl() {
        const exportFormats = [];
        if (this.uglSelected) {
            exportFormats.push('UGL');
        }

        if (this.gaebD81Selected) {
            exportFormats.push('GAEB_D81');
        }

        if (this.gaebD83Selected) {
            exportFormats.push('GAEB_D83');
        }

        if (this.gaebX94Selected) {
            exportFormats.push('GAEBXML_X94');
        }
        const params = new HttpParams({
            fromObject: {
                printType: this.printType,
                language: this.appService.getLanguageOnly(),
                country: this.appService.getLocation(),
                salesOrganization: this.appService.getSalesOrg(),
                exportFormats,
                schemes: this.getSelectedSchemes(),
                schemesDwt: this.getSelectedSchemes(SchemeType.dwt),
                ...(this.energyLabelSelected &&
                    !this.disableEnergyLabelCheckbox && { energyLabel: this.energyLabelHash }),
                offerAttachmentDocumentUris: this.getSelectedAttachments(true),
            },
        });
        return `${environment.http.baseUrl}quote/${this.documentId}/revision/${
            this.latestRevision
        }/zip?${params.toString()}`;
    }

    public getViBooksUrl() {
        const viBooksBaseUrl = environment.http.viBooksBaseUrl;
        return `${viBooksBaseUrl}/${this.appService.getLocation()}/${this.appService.getLanguageOnly()}?salesDocumentNumber=${
            this.sapDocumentId
        }`;
    }
    public toggleAll() {
        const selected = !this.isAllSelected();
        this.uglSelected = this.gaebX94Selected = this.gaebD81Selected = this.gaebD83Selected = selected;
        if (!this.disableEnergyLabelCheckbox) {
            this.energyLabelSelected = selected;
        }
        this.toggleSchemes(selected);
        this.toggleAttachments(selected);
    }

    public isAllSelected() {
        const everyThingBesidesSchemesSelected =
            this.uglSelected && this.gaebX94Selected && this.gaebD81Selected && this.gaebD83Selected;
        this.isAllAttachmentsSelected();
        return this.allSchemeControls().length
            ? everyThingBesidesSchemesSelected && this.isAllSchemesSelected()
            : everyThingBesidesSchemesSelected;
    }

    public isIndeterminate() {
        return (
            !this.isAllSelected() &&
            ([
                this.uglSelected,
                this.gaebD81Selected,
                this.gaebD83Selected,
                this.gaebX94Selected,
                this.energyLabelSelected,
            ].some(Boolean) ||
                this.allSchemeControls().some((c) => c.value))
        );
    }

    public toggleAllSchemes() {
        const allSelected = this.isAllSchemesSelected();
        this.toggleSchemes(!allSelected);
    }

    public isAllSchemesSelected() {
        const controls = this.allSchemeControls();
        return controls.length && controls.every((control) => control.value);
    }

    public isSchemesIndeterminate() {
        return !this.isAllSchemesSelected() && this.allSchemeControls().some((c) => c.value);
    }

    public schemeDownloadUrl(schemeId: string, type: string) {
        return `${environment.http.pim}schemes/${schemeId}?type=${type}&utm_source=quote-assist-pro`;
    }

    public exportFormatDownloadUrl(format: string) {
        const params = new HttpParams({
            fromObject: {
                printType: this.printType,
                language: this.appService.getLanguageOnly(),
                country: this.appService.getLocation(),
                format,
            },
        });
        return `${environment.http.baseUrl}quote/${this.documentId}/revision/${
            this.latestRevision
        }/export?${params.toString()}`;
    }

    public schemeControlName(scheme: string, type: SchemeType) {
        return `${scheme}-${type}`;
    }

    private getSelectedSchemes(type: SchemeType = SchemeType.scheme) {
        return this.schemeIds
            ? [...(this.schemeIds?.primary || []), ...(this.schemeIds?.secondary || [])].filter(
                  (id) => this.schemeForm.controls[this.schemeControlName(id, type)].value
              )
            : [];
    }

    private toggleSchemes(selected: boolean) {
        this.allSchemeControls().forEach((control) => {
            control.setValue(selected);
        });
    }

    public allSchemeControls() {
        return Object.values(this.schemeForm.controls);
    }

    private toggleAttachments(selected: boolean) {
        this.allAttachmentsControls().forEach((control) => {
            control.setValue(selected);
        });
    }

    public isAllAttachmentsSelected() {
        const controls = this.allAttachmentsControls();
        return controls.length && controls.every((control) => control.value);
    }

    allAttachmentsControls() {
        return Object.values(this.attachmentForm.controls);
    }

    private getQuote() {
        this.quoteService
            .fetchQuote({ documentId: this.documentId, latestRevision: this.latestRevision }, true)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ({ schemePdfs, energyLabelHash, externalApp }) => {
                    this.setEnergyLabelHash(energyLabelHash);
                    this.setSchemePdfs(schemePdfs);
                    if (externalApp) {
                        this.appService.appTitle$.next(externalApp);
                    }
                },
                () => {
                    this.onError('NEXT_STEPS.SNACK_BAR.FETCH_QUOTE.ERROR');
                }
            );
    }

    private setSchemePdfs(schemeIds?: { primary?: string; secondary?: string }) {
        const primary = (schemeIds.primary?.split(';') || []).filter(Boolean);
        const secondary = (schemeIds.secondary?.split(';') || []).filter(Boolean);
        this.schemeIds = {
            primary,
            secondary,
        };
        [...primary, ...secondary].forEach((scheme) =>
            this.schemeTypes.forEach((type) =>
                this.schemeForm.addControl(this.schemeControlName(scheme, type), new FormControl(false))
            )
        );
    }

    private setEnergyLabelHash(hash: string) {
        if (hash && hash !== this.noRelevanceKey) {
            // hash is present so generate energy label link
            const url = this.urlService.getUrl('pdf.energyLabel.download', {
                hash,
            });

            this.energyLabelPdfDownload = {
                isError: false,
                url,
            };

            this.energyLabelHash = hash;
            return;
        }

        this.energyLabelPdfDownload = { isError: true, url: '' };
    }

    private fetchPrintOfferPdf() {
        const printOfferPayload: PrintOfferPayload = {
            language: this.appService.getLanguageOnly(),
            country: this.appService.getLocation(),
            documentId: this.documentId,
            latestRevision: this.latestRevision,
            printType: this.printType,
        };

        this.dataService.fetchOfferPdf(printOfferPayload).subscribe(
            (res) => {
                if (res.isError) {
                    this.onError('NEXT_STEPS.QUOTE_PDF.ERROR_MSG');
                }
                this.quotePdfDownload = res;
            },
            () => {
                this.onError('NEXT_STEPS.QUOTE_PDF.ERROR_MSG');
                this.quotePdfDownload = { isError: true, url: '' };
            }
        );
    }
    private onError(translationKey: string) {
        if (this.permissionService.isUserAuthorized$.value) {
            this.snackBarService.openSnackBar({
                message: this.translateService.instant(translationKey),
                isFailure: true,
            });
        }
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // Attachments

    private getSelectedAttachments(download: boolean) {
        const uAIds = this.uploadAttachmentsIds.filter((id) => this.attachmentForm.controls[id].value);
        const uAFilter = this.uploadAttachments.filter((x) => uAIds.includes(x.id));
        this.uploadAttachmentsSelected = [];
        this.uploadAttachmentsSelectedUris = [];
        uAFilter.forEach((attachment) => {
            if (download) {
                this.uploadAttachmentsSelectedUris.push(attachment.documentUri);
            } else {
                this.uploadAttachmentsSelected.push({
                    id: attachment.id,
                    documentUri: attachment.documentUri,
                    filename: attachment.filename,
                });
            }
        });
        if (download) {
            return this.uploadAttachmentsSelectedUris;
        } else {
            return this.uploadAttachmentsSelected;
        }
    }

    setDownloadUrl(uri) {
        return this.dataService.downloadAttachment({
            documentId: this.documentId,
            latestRevision: this.latestRevision,
            documentUri: uri,
        });
    }

    // Upload

    refreshFileList(): void {
        this.dataService
            .getAttachments({
                documentId: this.documentId,
                latestRevision: this.latestRevision,
            })
            .subscribe((uploads) => {
                this.uploadFileControls = [];
                this.uploadAttachments = [];
                uploads.attachments.reverse().forEach((attachment) => {
                    const name = attachment.filename;
                    const file = {
                        id: attachment.id,
                        name,
                    };

                    this.uploadFileControls.push({
                        control: new FormControl({ name }),
                        file: <UploadFile>file,
                        uploading: false,
                    });
                    // Add seperate Attachments stuff
                    this.attachmentForm.addControl(attachment.id, new FormControl(false));
                    this.uploadAttachments.push(attachment);
                    this.uploadAttachmentsIds.push(attachment.id);
                });
                this.addFileControl();
                this.uploading = false;
            });
    }

    addFileControl(file?: UploadFile) {
        if (file && this.uploadFileControls.length) {
            const last = this.uploadFileControls[this.uploadFileControls.length - 1];
            last.file = file;
            last.queued = true;
        }
        this.uploadFileControls.push({
            control: new FormControl(),
            file: undefined,
            uploading: false,
        });
    }

    protected invalid(uploadFileControl: UploadFileControl): ValidationErrors {
        if (uploadFileControl.file && uploadFileControl.file.size > MAX_FILE_SIZE) {
            this.set(uploadFileControl); // set for display
            return {
                max: { max: humanizeBytes(MAX_FILE_SIZE) },
            };
        }

        return null;
    }

    protected start(): void {
        this.uploadFileControls.forEach((uploadFile) => {
            const invalid = this.invalid(uploadFile);
            if (invalid) {
                // remove from queue
                this.startUpload.emit({ type: 'remove', id: uploadFile.file.id });
                uploadFile.control.setErrors(invalid);
            }
        });
        this.startUpload.emit({
            url: this.urlService.getUrl('next-steps.attachments', {
                documentId: this.documentId,
                latestRevision: this.latestRevision,
            }),
            type: 'uploadAll',
            method: 'POST',
            headers: {
                Authorization: `CSRF ${this.auth.csrfToken$.value}`,
            },
        });

        this.dragging = false;
    }

    protected set(uploadFileControl: UploadFileControl): void {
        const file = uploadFileControl.file;
        // prepare file data
        const name = file.name;
        const size = humanizeBytes(file.nativeFile.size);

        // set control value
        uploadFileControl.control.setValue({ name, size });
    }

    find(file: UploadFile) {
        return this.uploadFileControls.find((ufc) => ufc.file.id === file.id);
    }

    protected success(status: number): boolean {
        return status >= 200 && status < 400;
    }

    uploadInProgress(): boolean {
        return this.uploadFileControls.some((ufc) => ufc.uploading);
    }

    atLeastOneFileUploaded() {
        return this.uploadFileControls.length > 1 && this.uploadFileControls.some((ufc) => !!ufc.file);
    }

    deleteAttachment(uploadFileControl: UploadFileControl): void {
        uploadFileControl.deleting = true;
        this.uploading = uploadFileControl.deleting;
        this.dataService
            .deleteAttachment({
                documentId: this.documentId,
                latestRevision: this.latestRevision,
                id: uploadFileControl.file.id,
            })
            .subscribe(
                (deleted) => {
                    if (deleted) {
                        this.refreshFileList();
                    }
                },
                (error) => {
                    if (error) {
                        uploadFileControl.deleting = false;
                        this.uploading = uploadFileControl.deleting;
                    }
                }
            );
    }

    cancel(uploadFileControl: UploadFileControl): void {
        const index = this.uploadFileControls.indexOf(uploadFileControl);
        this.uploadFileControls.splice(index, 1);
        // reset value to allow choosing the same by button (for file only that way is available)
        if (this.input) {
            this.input.nativeElement.type = '';
            this.input.nativeElement.type = 'file';
        }
        this.startUpload.emit({ type: 'cancel', id: uploadFileControl.file.id });
    }

    onUpload(event: UploadOutput): void {
        switch (event.type) {
            case 'cancelled': {
                break;
            }
            case 'dragOver': {
                this.dragging = true;
                break;
            }
            case 'dragOut': {
                this.dragging = false;
                break;
            }
            case 'allAddedToQueue':
                return this.start();
            case 'addedToQueue': {
                this.addFileControl(event.file);
                this.uploading = true;
                break;
            }
            case 'uploading': {
                const ufc = this.find(event.file);
                ufc.uploading = event.file.progress.data;
                ufc.queued = false;
                break;
            }
            case 'done': {
                const file = event.file;
                const uploadFile = this.find(file);
                this.set(uploadFile);
                if (!this.success(file.responseStatus)) {
                    // means not uploaded
                    uploadFile.control.setErrors({ upload: file });
                }
                this.find(event.file).uploading = undefined;
                const uploadInProgress = this.uploadInProgress();
                if (!uploadInProgress) {
                    // all files have been uploaded, refresh file list from backend
                    this.refreshFileList();
                }
                break;
            }
        }
    }

    private fetchQuoteEmailText() {
        this.dataService
            .getQuoteEmailText(
                this.documentId,
                this.latestRevision,
                this.appService.getLanguageOnly(),
                this.appService.getLocation()
            )
            .subscribe((mail) => {
                this.mailText$.next(mail.text || '');
            });
    }

    private fetchEmailRecipients(customerNumber: string) {
        return this.dataService.getEmailRecipients(customerNumber).pipe(
            map((recipients: Recipient[]) => {
                const recipientEmails = recipients
                    .map((recipient) => recipient.email)
                    .filter((email) => email && email.trim() !== '');
                return Array.from(new Set(recipientEmails));
            })
        );
    }
}
