import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GlobalHeaderModule } from '@vi/global-header';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ConfigitApiService } from './_shared/components/questionnaire/configit-quest-adapter/configit-api.service';
import { AuthService } from './_shared/services/auth.service';
import { AppHttpInterceptor } from './_shared/services/http-interceptor.service';
import { PermissionService } from './_shared/services/permission.service';
import { StatusPageService } from './_shared/services/status-page.service';
import { SharedModule } from './_shared/shared.module';
import { FooterComponent } from './app-footer/footer.component';
import { HeaderComponent } from './app-header/header.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ConfigurationModule } from './configuration/configuration.module';
import { FeatureBannerComponent } from './feature-banner/feature-banner.component';
import { ForbiddenModule } from './forbidden/forbidden.module';
import { InstanaErrorHandler } from './instana-error-handler';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { NextStepsModule } from './next-steps/next-steps.module';
import { OverviewModule } from './overview/overview.module';
import { SummaryModule } from './summary/summary.module';

function initializeApp(
    auth: AuthService,
    permissions: PermissionService,
    statusPage: StatusPageService,
    api: ConfigitApiService
): () => Promise<any> {
    return () =>
        statusPage
            .checkForDowntime()
            .pipe(
                concatMap((down) => {
                    if (down) {
                        permissions.isUserAuthorized$.next(true);
                        permissions.isUserLoggedIn$.next(true);
                        return of(true);
                    } else {
                        auth.init();
                        permissions.getLoggedInUserInfo();
                        return api.fetchConfigitSettings();
                    }
                })
            )
            .toPromise();
}

@NgModule({
    declarations: [AppComponent, HeaderComponent, FooterComponent, FeatureBannerComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AuthService, PermissionService, StatusPageService, ConfigitApiService],
            multi: true,
        },
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        OverviewModule,
        ConfigurationModule,
        NextStepsModule,
        ForbiddenModule,
        MaintenanceModule,
        SummaryModule,
        GlobalHeaderModule.forRoot({
            ...environment.globalHeader,
            appId: environment.saml.appId,
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
