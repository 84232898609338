<mat-table matSortActive="createdDate" matSortDirection="desc" class="overview-table" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="documentId">
        <mat-header-cell *matHeaderCellDef>
            {{ 'OVERVIEW.TABLE.COLUMN.OFFER_NUMBER' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.OFFER_NUMBER' | translate }}:</span>
            <span data-at-quote-number>{{ obj.quoteId }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="schemePreview">
        <mat-header-cell *matHeaderCellDef>
            {{ 'OVERVIEW.TABLE.COLUMN.SCHEME' | translate }}
        </mat-header-cell>
        <mat-cell
            *matCellDef="let obj"
            (mouseenter)="onImageHover($event, obj)"
            (mousemove)="onImageHover($event, obj)"
            (mouseleave)="onImageHover($event, obj)"
        >
            <div class="cell-spacing">
                <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.SCHEME' | translate }}:</span>
                <span
                    *ngIf="
                        obj.status.toLowerCase() === 'new' ||
                        (!obj.scheme?.url && !obj.scheme?.preview && !obj.scheme?.id)
                    "
                    >-</span
                >
                <a
                    [href]="obj.scheme?.url"
                    target="_blank"
                    *ngIf="
                        obj.status.toLowerCase() !== 'new' && obj.scheme?.url && obj.scheme?.preview && obj.scheme?.id
                    "
                >
                    {{ obj.scheme?.id }}
                </a>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="projectId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.PROJECT_NUMBER' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.PROJECT_NUMBER' | translate }}:</span>
            <span data-at-created-at>{{ obj.projectIdSf }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partnerNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.PARTNER_NUMBER' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)" matTooltip="{{ obj.companyName }}">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.PARTNER_NUMBER' | translate }}:</span>
            <span data-at-created-at>{{ obj.companyNumber }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partnerName">
        <mat-header-cell *matHeaderCellDef>
            {{ 'OVERVIEW.TABLE.COLUMN.PARTNER_NAME' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.PARTNER_NAME' | translate }}:</span>
            <span data-at-created-at>{{ obj.companyName }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.CREATED_AT' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.CREATED_AT' | translate }}:</span>
            <span data-at-created-at>{{ obj.createdDate | dateFormat: 'dotDate' }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.NAME' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <div class="cell-spacing">
                <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.NAME' | translate }}:</span>
                <span data-at-quote-title>{{ obj.title }}</span>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalGross">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.TOTAL_GROSS' | translate }}
        </mat-header-cell>
        <mat-cell
            *matCellDef="let obj"
            (click)="onViewClick(obj)"
            (mouseenter)="handlePriceSummaryEvent($event, obj)"
            (mouseleave)="handlePriceSummaryEvent($event, obj)"
            (mousemove)="handlePriceSummaryEvent($event, obj)"
        >
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.TOTAL_GROSS' | translate }}:</span>
            <span data-at-created-at>{{
                obj.totalGross !== 0 ? (obj.totalGross | currency: obj.currency:'symbol-narrow') : '-'
            }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="creator">
        <mat-header-cell *matHeaderCellDef>
            {{ 'OVERVIEW.TABLE.COLUMN.CREATOR' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.CREATOR' | translate }}:</span>
            <span data-at-created-at>{{ obj.authorLogin }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="creatorFullName">
        <mat-header-cell *matHeaderCellDef>
            {{ 'OVERVIEW.TABLE.COLUMN.CREATOR' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.CREATOR' | translate }}:</span>
            <span data-at-created-at>{{ getCreatorFullName(obj) }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.STATUS' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" (click)="onViewClick(obj)">
            <span class="mobile-label"> {{ 'OVERVIEW.TABLE.COLUMN.STATUS' | translate }}:</span>
            <span data-at-status>{{ 'OVERVIEW.TABLE.OFFER_STATUS.' + obj.status.toUpperCase() | translate }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let obj">
            <div class="sm-screen-btns">
                <button mat-button color="primary" (click)="onCopyClick(obj)">
                    <mat-icon>content_copy</mat-icon>
                    {{ 'OVERVIEW.ACTIONS.COPY' | translate }}
                </button>
                <button mat-button color="primary" (click)="onDeleteClick(obj)">
                    <mat-icon>delete</mat-icon>
                    {{ 'OVERVIEW.ACTIONS.DELETE' | translate }}
                </button>
            </div>
            <div class="lg-screen-btns">
                <button
                    mat-icon-button
                    color="primary"
                    (click)="onCopyClick(obj)"
                    matTooltip="{{ 'OVERVIEW.ACTIONS.COPY' | translate }}"
                >
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button
                    mat-icon-button
                    color="primary"
                    matTooltip="{{ 'OVERVIEW.ACTIONS.DELETE' | translate }}"
                    (click)="onDeleteClick(obj)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
</mat-table>

<mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>

<div *ngIf="!dataSource?.hasData()" fxLayout="row" fxLayoutAlign="center center">
    <p data-at-no-results>{{ 'COMMON.NO_RESULTS' | translate }}</p>
</div>
