<div class="dialog-container">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
        <mat-form-field fxFlex class="bordered-field">
            <mat-label>{{ 'CONFIGURATION.OFFER_TITLE_PLACEHOLDER' | translate }}</mat-label>
            <input matInput maxlength="100" [(ngModel)]="offerTitle" required />
            <mat-hint align="end">{{ offerTitle.length }} / 100</mat-hint>
            <mat-error> {{ 'CONFIGURATION.VALIDATION.PLEASE_PROVIDE_TITLE_MESSAGE' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-button [mat-dialog-close]="false">{{ data.cancel }}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="offerTitle" [disabled]="!offerTitle">
            {{ data.confirm }}
        </button>
    </div>
</div>
