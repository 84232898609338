<div *ngIf="!isInternetExplorer" class="root" fxLayout="column">
    <ng-container *ngIf="(permissionService.isUserAuthorized$ | async) !== undefined">
        <div class="navbar" fxLayout="column" fxLayoutAlign="space-between">
            <app-header></app-header>
        </div>
        <app-feature-banner></app-feature-banner>
        <app-maintenance-message></app-maintenance-message>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <div class="footer">
            <dsw-footer cookies></dsw-footer>
        </div>
    </ng-container>

    <div *ngIf="(permissionService.isUserAuthorized$ | async) === undefined">
        <div class="spinnerbox">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
                <h3 class="spinnertext">Loading...</h3>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isInternetExplorer" class="ie-wrapper">
    <div class="ie-header">
        <div fxLayout="column">
            <div
                fxFlex="68px"
                fxLayout="row"
                fxLayoutGap="20px"
                fxLayoutGap.xs="10px"
                fxLayoutAlign="space-between center"
            >
                <div fxFlex>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="10px" fxLayoutAlign="start center">
                        <app-modular-energy-center-logo
                            [title]="'HEADER.APP-TITLE' | translate"
                        ></app-modular-energy-center-logo>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ie-msg-container">
        {{ 'COMMON.IE_NOT_SUPPORTED_MSG' | translate }}
    </div>
    <div style="text-align: center; margin-top: 20px">
        <a href="https://www.google.com/chrome/">
            <img src="../assets/images/Internet-chrome-icon.png" />
        </a>
    </div>
</div>
