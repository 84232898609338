<div class="row" fxLayout="row" fxLayoutAlign="space-between baseline">
    <div>{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
    <div>{{ data.totalGross | currency : data.currency:'symbol-narrow' }}</div>
</div>
<div
    class="row"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    *ngIf="data.revisions[0].totalDiscount?.doubleValue"
>
    <div>{{ 'CONFIGURATION.PANEL.LABEL.PACKAGE_PRICE_ADVANTAGE' | translate }}:</div>
    <div class="discount">
        - {{ data.revisions[0].totalDiscount?.doubleValue | currency : data.currency:'symbol-narrow' }}
    </div>
</div>

<div
    class="row"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    *ngIf="data.revisions[0].totalDiscount?.doubleValue && data.revisions[0].totalPrice?.doubleValue"
>
    <div>{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_PRICE' | translate }}:</div>
    <div>{{ data.revisions[0].totalPrice?.doubleValue | currency : data.currency:'symbol-narrow' }}</div>
</div>

<div
    class="row total"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    *ngIf="data.revisions[0].netPrice?.doubleValue"
>
    <div>{{ 'CONFIGURATION.PANEL.LABEL.NET_PRICE' | translate }}:</div>
    <div>{{ data.revisions[0].netPrice?.doubleValue | currency : data.currency:'symbol-narrow' }}</div>
</div>
