import { DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PermissionService } from './permission.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public csrfToken$ = new BehaviorSubject(undefined);
    private fetchNewCsrfToken$: Observable<CsrfTokenResponse>;
    private httpClient: HttpClient;
    private jwtHelper: JwtHelperService;
    private config: SamlConfiguration;
    private CSRF_TOKEN_STORAGE_KEY = 'qa_csrf_token';
    constructor(
        handler: HttpBackend,
        private permissions: PermissionService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.config = environment.saml;
        this.httpClient = new HttpClient(handler);
        this.jwtHelper = new JwtHelperService();
        this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
            tap({
                next: ({ token }) => this.csrfToken$.next(token),
                error: () => this.redirectToLoginScreen(),
            }),
            share()
        );
    }

    public init() {
        this.csrfToken$.subscribe((jwtToken) => {
            if (!jwtToken) {
                return;
            }
            localStorage.setItem(this.CSRF_TOKEN_STORAGE_KEY, jwtToken);
            const payload = <CsrfTokenPayload>this.jwtHelper.decodeToken(jwtToken);
            // Fill object with data from token.
            // this will allow app to find that user is authenticated earlier, but more details will be loaded by heder component
            this.permissions.isUserLoggedIn$.next(true);
            this.permissions.userInfo$.next({
                ...this.permissions.userInfo$.value,
                viCompanyId: payload.company,
                identityType: payload.identityType,
                role: this.permissions.mapIdentityTypeToRole(payload.identityType),
            });
        });

        const token = this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

        if (token && this.isCsrfTokenValid(token)) {
            this.csrfToken$.next(token);
        } else {
            this.fetchNewCsrfToken$.subscribe();
        }
    }

    public onLogout() {
        localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    public fetchNewCsrfToken() {
        return this.fetchNewCsrfToken$;
    }
    private redirectToLoginScreen() {
        localStorage.setItem('lastPath', this.document.location.pathname);
        localStorage.setItem('lastSearch', this.document.location.search);
        this.document.location.href = `${this.config.baseUrl}/saml/sso/request?appId=${this.config.appId}`;
    }

    private getCsrfTokenRequest() {
        return this.httpClient.post<CsrfTokenResponse>(
            `${this.config.baseUrl}/auth/v1/saml/csrf`,
            {
                appId: this.config.appId,
            },
            {
                withCredentials: true,
            }
        );
    }

    private getCsrfTokenFromUrl(): string | undefined {
        const value = new HttpParams({ fromString: window.location.hash }).get('#token');
        window.location.hash = ''; // remove token form url
        return value;
    }

    private getCsrfTokenFromLocalStorage(): string | undefined {
        return localStorage.getItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    private isCsrfTokenValid(token: string) {
        try {
            return !this.jwtHelper.isTokenExpired(token);
        } catch (_) {
            return false;
        }
    }
}

interface CsrfTokenResponse {
    token: string;
}

interface CsrfTokenPayload {
    'x-uuid': string;
    roles: string;
    company?: string;
    identityType: string;
    login: string;
    sessionId: string;
    appId: string;
    iss: string;
}

export interface SamlConfiguration {
    appId: string;
    baseUrl: string;
}
