import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { forbiddenAuthGuardService } from './forbidden/forbidden.auth.guard.service';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
    { path: '', redirectTo: '/overview/quotes', pathMatch: 'full' },
    {
        path: 'overview',
        redirectTo: '/overview/quotes',
        pathMatch: 'full',
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        pathMatch: 'full',
        canActivate: [forbiddenAuthGuardService],
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
