import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { UrlService } from '../../services/url.service';

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryDataService {
    public isPriceSummaryLoading$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    public hasErrors$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    constructor(private urlService: UrlService, private httpService: HttpService) {}

    public fetchPriceSummary(param: any): Observable<any> {
        const url = this.urlService.getUrl('price.summary');
        return this.httpService.post(url, param);
    }
}
