import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../_shared/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class UnitTestsService {
    constructor(private httpService: HttpService) {}

    public getUnitTests() {
        const url = `${environment.http.baseUrl}quote/unit-tests`;
        return this.httpService.get(url);
    }

    public startSingleUnitTest(documentId: string, revision: number) {
        const url = `${environment.http.baseUrl}quote/unit-tests/${documentId}/revision/${revision}/execute`;
        return this.httpService.post(url);
    }

    public getPimProducts(materials: string[]) {
        const url = `${environment.http.pim}products/de/de`;
        return this.httpService.post(url, { products: materials });
    }
}
