<app-card [isFooter]="false" [isHeader]="true">
    <div header fxLayoutAlign="space-between">
        <h2 class="product-list-title">{{ 'CONFIGURATION.PANEL.TITLE.LIST_OF_PRODUCTS' | translate }}</h2>
    </div>
    <div body>
        <mat-table class="product-table" [dataSource]="dataSource">
            <ng-container matColumnDef="product-group">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.PRODUCT_GROUP' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">
                    <h3 class="group-name">
                        {{ obj.groupNameTranslation | translate }}
                    </h3>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.IMAGE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">
                    <img
                        class="product-image"
                        [src]="productService.getProductImage(obj.material) | async"
                        *ngIf="!obj.groupNameTranslation"
                    />
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="partNumber">
                <mat-header-cell data-at-part-number *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.PART_NUMBER' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">{{ obj.material }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-header-cell data-at-quantity *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.QUANTITY' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">
                    <mat-form-field *ngIf="isExternalApp" class="quantity">
                        <input
                            matInput
                            type="number"
                            [formControl]="obj.formGroup.controls.quantity"
                            (change)="changeQuantity(obj)"
                        />
                    </mat-form-field>
                    <ng-container *ngIf="!isExternalApp">{{ obj.quantity }}</ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell data-at-desciption *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.DESCRIPTION' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">{{ chooseTranslation(obj.translation) }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="materialGroup">
                <mat-header-cell data-at-desciption *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.MATERIAL_GROUP' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">{{ obj.materialGroup }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="isOptional">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SUMMARY.PRODUCT_TABLE.COLUMN_HEADER.IS_OPTIONAL' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let obj">
                    <mat-checkbox
                        [formControl]="obj.formGroup.controls.optional"
                        (change)="setOptional(obj)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let obj">
                    <button
                        mat-button
                        color="primary"
                        matTooltip="{{ 'SUMMARY.PRODUCT_TABLE.ACTIONS.DELETE' | translate }}"
                        (click)="deleteProduct(obj)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row data-at-product *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
        </mat-table>
    </div>
</app-card>
