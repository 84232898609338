import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface QuoteCreationDialogData {
    title: string;
    confirm: string;
    cancel: string;
    quoteTitle?: string;
}

export function openQuoteCreationDialog(
    dialog: MatDialog,
    title: string,
    confirm: string,
    cancel: string,
    quoteTitle?: string
) {
    const dialogRef = dialog.open(QuoteCreationDialogComponent, {
        autoFocus: false,
        width: '500px',
        data: {
            title,
            confirm,
            cancel,
            quoteTitle,
        },
    });
    return dialogRef.afterClosed();
}

@Component({
    selector: 'app-quote-creation-dialog',
    templateUrl: './quote-creation-dialog.component.html',
    styleUrls: ['./quote-creation-dialog.component.scss'],
})
export class QuoteCreationDialogComponent implements OnInit {
    public offerTitle: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: QuoteCreationDialogData) {}

    ngOnInit(): void {
        this.offerTitle = this.data.quoteTitle?.length ? this.data.quoteTitle : '';
    }
}
