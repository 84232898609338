import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef, DialogPosition } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

/**
 * Generic helper class that opens the specified dialog with the passed data and keeps it open at the current mouse cursor position.
 * The handleEvent method should be called on events like mouse enter, move, leave.
 */
export class OverlayDialogHelper<T, D extends { quoteId: string }> {
    private current: string;
    private currentDialogHeight = 0;
    private dialogRef: MatDialogRef<T>;

    constructor(
        private document: Document,
        private dialog: MatDialog,
        private dialogType: ComponentType<T>,
        private panelClass: string
    ) {}

    public handleEvent(event: MouseEvent, obj: D) {
        switch (event.type) {
            case 'mouseleave': {
                this.current = null;
                this.currentDialogHeight = 0;
                if (this.dialogRef) {
                    this.dialogRef.close();
                }
                break;
            }
            case 'mouseenter': {
                this.mouseEnter(event, obj);
                break;
            }
            case 'mousemove': {
                if (this.dialogRef) {
                    this.dialogRef.updatePosition(this.getDialogPosition(event));
                }
                break;
            }
        }
    }

    private mouseEnter(event: MouseEvent, data: D) {
        if (this.dialogRef && this.current === data.quoteId) {
            return;
        }
        this.current = data.quoteId;
        this.dialogRef = this.dialog.open(this.dialogType, {
            hasBackdrop: false,
            width: '600px',
            panelClass: this.panelClass,
            position: this.getDialogPosition(event),
            data,
        });
        this.dialogRef
            .afterOpened()
            .subscribe(
                () => (this.currentDialogHeight = this.document.getElementById(this.dialogRef.id)?.offsetHeight || 0)
            );
        this.dialogRef.afterClosed().pipe(finalize(() => (this.dialogRef = undefined)));
    }

    private getDialogPosition(event: MouseEvent): DialogPosition {
        // show the dialog at the position of the cursor.
        // attach the upper left or bottom left corner of the dialog to the cursor, depending on where it fits on the screen.
        // for this we use an offset for "top" with the height
        const offset = event.clientY + this.currentDialogHeight > window.innerHeight ? this.currentDialogHeight : 0;
        // 0 means upper left corner of dialog will be where mouse cursor is
        return {
            left: `${event.x + 10}px`,
            top: `${event.y - offset}px`,
        };
    }

    public destroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
