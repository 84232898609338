import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { PdfDownloadUrl } from '../documents-selection.component';

@Component({
    selector: 'app-document-actions',
    templateUrl: './document-actions.component.html',
    styleUrls: ['./document-actions.component.scss'],
})
export class DocumentActionsComponent {
    /** Model that contains the url to the resource, either an url string or an object that can indicate error */
    @Input() public model: PdfDownloadUrl | string;

    /** True if "download" link should be rendered, with query param ?download=true appended */
    @Input() public download = false;

    /** True if "open in new tab" link should be rendered, that opens resource inline in browser */
    @Input() public inline = false;

    public get simple() {
        return typeof this.model === 'string';
    }

    public get complete() {
        if (this.simple) {
            return true;
        }
        const model = <PdfDownloadUrl>this.model;
        return model?.url && !model?.isError;
    }

    public get loading() {
        if (this.simple) {
            return false;
        }
        const model = <PdfDownloadUrl>this.model;
        return !model?.url && !model?.isError;
    }

    public get hasError() {
        if (this.simple) {
            return false;
        }
        const model = <PdfDownloadUrl>this.model;
        return model?.isError;
    }

    public url(download = false) {
        if (this.model) {
            const url = typeof this.model === 'string' ? this.model : this.model.url;

            if (url && download) {
                // append download="true" to query parameters
                const search = new URL(url).search;
                const fromString = search.startsWith('?') ? search.slice(1) : search;
                let params = new HttpParams({ fromString });
                params = params.append('download', 'true');
                const query = params.keys().length ? `?${params.toString()}` : '';
                return `${url.split('?')[0]}${query}`;
            }
            return url;
        }
        return '';
    }
}
