import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PriceSummaryDialogData {
    quoteId: string;
    totalGross: number;
    netPrice: number;
    voucherCode: string;
    voucherValue: number;
    currency: string;
    noPriceAdvantage: boolean;
    revisions: any;
}

@Component({
    selector: 'app-price-summary-dialog',
    templateUrl: './price-summary-dialog.component.html',
    styleUrls: ['./price-summary-dialog.component.scss'],
})
export class PriceSummaryDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: PriceSummaryDialogData) {}
}
