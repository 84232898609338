import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../_shared/guards/auth.guard';
import { maintenanceGuard } from '../_shared/guards/maintenance.guard';
import { SummaryComponent } from './summary.component';

const summaryRoutes: Routes = [
    {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [authGuard, maintenanceGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(summaryRoutes)],
    exports: [RouterModule],
})
export class SummaryRouting {}
