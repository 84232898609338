import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-modular-energy-center-logo',
    templateUrl: './modular-energy-center-logo.component.html',
    styleUrls: ['./modular-energy-center-logo.component.scss'],
})
export class ModularEnergyCenterLogoComponent {
    @Input()
    public externalApp: string;
}
