import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
    public date;

    constructor(private datePipe: DatePipe) {}

    public transform(value: any, exponent?: string): any {
        if (typeof value === 'number' || !isNaN(Number(value))) {
            return value;
        }

        this.date = new Date(value);

        if (Object.prototype.toString.call(this.date) === '[object Date]') {
            if (isNaN(this.date.getTime())) {
                return value;
            } else if (exponent === 'dotDate') {
                return this.datePipe.transform(this.date, 'dd.MM.yyyy');
            } else {
                return this.datePipe.transform(this.date, 'dd.MM.yyyy HH:mm');
            }
        } else {
            return value;
        }
    }
}
