import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../services/app.service';
import { PermissionService } from '../../services/permission.service';
import {
    CachedDiscountCategory,
    ProductsCategoryService,
    GenericArticleCondition,
    GenericArticleQuotationText,
} from '../../services/products-category.service';
import { Product, ProductGroup } from '../../services/products.service';
import { QuoteDiscount, QuoteDiscountsService } from '../../services/quote-discounts.service';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { TranslateService } from '@ngx-translate/core';

interface MaterialProduct {
    materialNumber: string;
    amount: number;
    category?: string;
}

export interface PriceParams {
    customerId?: string;
    salesOrg: string;
    materials?: MaterialProduct[];
    groupedMaterials?: {
        name: string;
        materials: MaterialProduct[];
    }[];
    discounts: CachedDiscountCategory[];
    conditions?: GenericArticleCondition[];
    quotationTexts?: GenericArticleQuotationText[];
    partnerDiscounts?: any[];
    salesforceId?: string;
    quotationRequestId?: string;
    voucherCode?: string;
}

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryHelperService {
    private materialCategory: Map<string, string> = new Map<string, string>();

    constructor(
        private permissionService: PermissionService,
        private quoteDiscountsService: QuoteDiscountsService,
        private selectedCustomerService: SelectedCustomerService,
        private appService: AppService,
        private productCategory: ProductsCategoryService,
        private translate: TranslateService
    ) {}

    public setCategoryForMaterial(material: string, category: string) {
        // we have to prepend a 'Z' for the actual category. SAP logic...
        this.materialCategory.set(material, `Z${category}`);
    }

    public getParamForQuoteClose(groupedProducts: ProductGroup[], customerId: string) {
        const params = this.getPriceParams(
            customerId,
            groupedProducts.flatMap((group) => group.products).map((p) => p.material)
        );
        params.groupedMaterials = groupedProducts.map((group) => ({
            name: this.translate.instant(group.translationKey),
            materials: group.products.map(this.convertProduct),
        }));

        return params;
    }

    public getParamForPriceSummary(products: Product[], customerId: string): PriceParams {
        const params = this.getPriceParams(
            customerId,
            products.map((p) => p.material)
        );

        params.materials = products.map(this.convertProduct);

        return params;
    }

    private convertProduct(product: Product) {
        return {
            materialNumber: product.material,
            amount: product.quantity,
            category: product.optional ? this.materialCategory.get(product.material) : undefined,
        };
    }

    private getPriceParams(customerId: string, materials: string[]): PriceParams {
        const param: PriceParams = {
            salesOrg: this.appService.getSalesOrg(),
            discounts: [],
        };
        if (customerId && customerId !== '') {
            param.customerId = customerId;
        }
        const salesforceId = this.appService.salesforceId$.value;
        if (salesforceId) {
            param.salesforceId = salesforceId;
        }

        const isPlannerOrArchitect = this.selectedCustomerService.isPlannerOrArchitect$.value;

        if (
            !environment.app.priceAdvantageNotRelevantSalesOrgs.includes('*') &&
            !environment.app.priceAdvantageNotRelevantSalesOrgs.includes(this.appService.getSalesOrg()) &&
            !isPlannerOrArchitect
        ) {
            param.discounts = this.productCategory.getProductDiscountCategoriesFromCache();
        }

        if (this.permissionService.isUserAnEmployee && this.quoteDiscountsService.getQuoteDiscounts().length > 0) {
            param.partnerDiscounts = this.quoteDiscountsService.getQuoteDiscounts();
        }

        const productsFilter = ({ materialNumber }: { materialNumber: string }) =>
            materials.some((material) => material === materialNumber);
        param.conditions = this.productCategory.getArticleConditionsFromCache().filter(productsFilter);
        param.quotationTexts = this.productCategory.getArticleQuotationTextsFromCache().filter(productsFilter);

        return param;
    }

    public hasProductListChanged(array1: any[], array2: any[]) {
        const arrayString1 = JSON.stringify(array1);
        const arrayString2 = JSON.stringify(array2);
        return arrayString1 !== arrayString2;
    }

    public quoteDiscountsDiffer(prev: QuoteDiscount[], current: QuoteDiscount[]): boolean {
        if (prev == null) {
            return true;
        }
        // 1. We convert each discount to a normalized string representation: GG_15
        // 2. we check the difference in both ways to determine if the arrays are equal
        const prevStrings = prev.map((it) => `${it.materialGroup}_${String(it.partnerDiscount)}`);
        const currentStrings = current.map((it) => `${it.materialGroup}_${String(it.partnerDiscount)}`);
        const diff1 = prevStrings.filter((it) => !currentStrings.includes(it));
        const diff2 = currentStrings.filter((it) => !prevStrings.includes(it));
        return diff1.length + diff2.length > 0;
    }

    public areAllProductCategoriesInProductList(products: Product[]) {
        const articleConditions = this.productCategory.getArticleConditionsFromCache();
        return (
            articleConditions?.length &&
            articleConditions.every(({ materialNumber }) => products.some((p) => p.material === materialNumber))
        );
    }
}
