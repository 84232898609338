import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { SharedModule } from '../_shared/shared.module';
import { SummaryComponent } from './summary.component';
import { SummaryRouting } from './summary.routing';

@NgModule({
    declarations: [SummaryComponent],
    imports: [CommonModule, SummaryRouting, SharedModule, MatRadioModule],
})
export class SummaryModule {}
