import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { catchError, concatMap, filter, map, take, takeUntil } from 'rxjs/operators';
import { LoadingOverlayComponent } from '../_shared/components/loading-overlay/loading-overlay.component';
import { PriceSummaryDataService } from '../_shared/components/price-summary/price-summary.data.service';
import { PriceSummaryHelperService } from '../_shared/components/price-summary/price-summary.helper.service';
import { ProductListService } from '../_shared/components/product-list/product-list.service';
import { AppService } from '../_shared/services/app.service';
import { PermissionService } from '../_shared/services/permission.service';
import { Product, ProductGroup, ProductsService } from '../_shared/services/products.service';
import { PrintType, QuoteService } from '../_shared/services/quote.service';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { SnackBarService } from '../_shared/services/snack-bar.service';
import { SummaryDataService } from './summary.data.service';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private dialogRef: MatDialogRef<LoadingOverlayComponent>;

    private documentId: string;
    private latestRevision: string;
    private quickRef: string;
    private title: string;
    private productList: Product[];
    public selectedCustomerId: string;
    public navigationParams: any;
    public dataSource: any;
    public isLoading = true;
    public voucher: string = null;
    public callbackUrl: string;
    public secondaryCallbackUrl: string;
    public externalApp: boolean;
    public availablePrintTypes: string[];
    public printType: PrintType;
    public quotationRequestId: string;

    constructor(
        private appService: AppService,
        private titleService: Title,
        private productsService: ProductsService,
        private router: Router,
        private priceSummaryService: PriceSummaryHelperService,
        private priceSummaryDataService: PriceSummaryDataService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private selectedCustomerService: SelectedCustomerService,
        private quoteService: QuoteService,
        private dataService: SummaryDataService,
        private productListService: ProductListService,
        public permissionService: PermissionService,
        private dialog: MatDialog
    ) {}

    public ngOnInit() {
        this.scrollToTop();
        const {
            documentId,
            latestRevision,
            quickRef,
            title,
            callbackUrl,
            secondaryCallbackUrl,
            salesforceId,
            quotationRequestId,
        } = this.activatedRoute.snapshot.queryParams;

        this.appService.salesOrg$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                concatMap((salesOrg) => this.dataService.getAvailablePrintTypes(salesOrg))
            )
            .subscribe((printTypes) => {
                this.availablePrintTypes = printTypes;
                // default should be 'L' if available, otherwise use 'M' or 'S'
                this.printType = <PrintType>['L', 'M', 'S'].find((t) => printTypes.includes(t));
            });
        this.quickRef = quickRef;
        this.documentId = documentId;
        this.latestRevision = latestRevision;
        this.title = title;
        this.callbackUrl = callbackUrl;
        this.secondaryCallbackUrl = secondaryCallbackUrl;
        if (salesforceId) {
            this.appService.salesforceId$.next(salesforceId);
        }
        if (quotationRequestId) {
            this.quotationRequestId = quotationRequestId;
        }

        this.navigationParams = {
            queryParams: {
                quickRef: this.quickRef,
                latestRevision: this.latestRevision,
                documentId: this.documentId,
                title: this.title,
                ...(quotationRequestId && { quotationRequestId }),
            },
        };

        this.quoteService
            .fetchQuote({ documentId, latestRevision })
            .pipe(take(1), takeUntil(this.ngUnsubscribe))
            .subscribe((quote) => {
                this.externalApp = !!quote.externalApp;
                this.isLoading = false;
                if (this.externalApp) {
                    this.appService.appTitle$.next(quote.externalApp);
                    this.titleService.setTitle(quote.externalApp);
                }
            });
        this.subscribeToProductList$();
        this.subscribeToSelectedCustomer$();
    }

    public priceSummaryIsLoading() {
        return this.priceSummaryDataService.isPriceSummaryLoading$;
    }

    public priceSummaryHasErrors() {
        return this.priceSummaryDataService.hasErrors$;
    }

    public updateVoucherCode(event: string) {
        this.voucher = event;
    }

    public closeOffer() {
        // if currently logged in user is a customer, just use the viCompanyId of the currently logged in user
        const isCustomerLoggedIn =
            this.permissionService.userInfo$.value && this.permissionService.userInfo$.value.role === 'customer';
        let customerIdForClose = 'no-customer-selected';
        if (this.selectedCustomerId) {
            customerIdForClose = this.selectedCustomerId;
        } else if (isCustomerLoggedIn) {
            // use the viCompanyId
            customerIdForClose = this.permissionService.userInfo$.value.viCompanyId;
        }

        const groupedProducts: ProductGroup[] = this.productsService.getProductListViewModel(this.productList, false);
        const payload = this.priceSummaryService.getParamForQuoteClose(groupedProducts, customerIdForClose);

        if (this.voucher) {
            payload.voucherCode = this.voucher;
        }
        if (this.quotationRequestId) {
            payload.quotationRequestId = this.quotationRequestId;
        }

        const params = { documentId: this.documentId, latestRevision: this.latestRevision };

        this.isLoading = true;

        this.quoteService
            .updateQuoteProperty('printType', this.printType)
            .pipe(
                concatMap(() => this.dataService.closeQuoteInSap(params, payload)),
                map((res: { salesDocumentNumber: string }) => res.salesDocumentNumber.toString()),
                concatMap((offerNumber) => {
                    const quote = this.quoteService.getCurrentQuote();
                    const lines = (quote?.quote?.lines || [])
                        .filter((l) => !l.isSubBomItem)
                        .map((line) => ({
                            variant: line.variantCode,
                            assignments: line.assignments,
                        }));
                    const reportingPaylad = {
                        projectId: quote?.projectId,
                        title: quote?.quote?.title,
                        offerNumber,
                        data: { lines },
                    };
                    return this.dataService.addReportingEntry(reportingPaylad).pipe(
                        map(() => offerNumber),
                        catchError(() => of(offerNumber))
                    );
                })
            )
            .subscribe(
                (sapId) => {
                    this.onSuccess('SUMMARY.SNACK_BAR.CLOSE_OFFER.SUCCESS');
                    this.router.navigate(['next-steps'], {
                        queryParams: {
                            documentId: this.documentId,
                            latestRevision: this.latestRevision,
                            sapDocumentId: sapId,
                            printType: this.printType,
                            callbackUrl: this.secondaryCallbackUrl,
                            ...(this.externalApp && { externalApp: this.externalApp }),
                        },
                    });
                },
                () => this.onError('SUMMARY.SNACK_BAR.CLOSE_OFFER.ERROR')
            );
    }

    public navigateBackToConfiguration() {
        this.router.navigate(['configuration'], this.navigationParams);
    }

    private subscribeToSelectedCustomer$() {
        this.selectedCustomerService.selectedCustomer$
            .pipe(takeUntil(this.ngUnsubscribe), filter(Boolean))
            .subscribe((customer: { number: string }) => {
                this.selectedCustomerId = customer.number;
            });
    }

    private subscribeToProductList$() {
        this.openDialog();
        this.productListService.productList$.pipe(takeUntil(this.ngUnsubscribe), filter(Boolean)).subscribe(
            (products: Product[]) => {
                this.productList = products;
                let productList = this.productsService.getProductListViewModel(products, true);
                if (this.externalApp) {
                    // remove external products group and add form controls for edit mode
                    productList = productList
                        .filter((o) => o.groupNameTranslation !== 'COMMON.CATEGORIES.EXTERNAL_PRODUCT_GROUP')
                        .map((product: Product) => ({
                            ...product,
                            formGroup: new UntypedFormGroup({
                                quantity: new UntypedFormControl(product.quantity),
                                optional: new UntypedFormControl(product.optional),
                            }),
                        }));
                }

                this.dataSource = new MatTableDataSource(productList);
                this.closeDialog();
            },
            () => {
                this.onError('CONFIGURATION.SNACK_BAR.FETCH_CONFIGURATION.ERROR');
                this.closeDialog();
            }
        );
    }

    private onError(translationKey: string) {
        this.isLoading = false;
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
            isFailure: true,
        });
    }

    private onSuccess(translationKey: string) {
        this.isLoading = false;
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
        });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private openDialog(): void {
        if (!this.dialogRef) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.width = '250px';
            dialogConfig.scrollStrategy = new NoopScrollStrategy();
            this.dialogRef = this.dialog.open(LoadingOverlayComponent, dialogConfig);
        }
    }

    private closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    private scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
}
