<div class="unit-tests-container">
    <div class="unit-tests-controls">
        <button mat-raised-button color="primary" (click)="startAllUnitTests()" [disabled]="disableTrigger">
            {{ 'OVERVIEW.UNIT_TESTS.BUTTON.START_ALL' | translate }}
        </button>
    </div>
    <div class="unit-tests">
        <div class="unit-test" *ngFor="let unitTest of unitTests">
            <div class="unit-test-interface">
                <div class="unit-test-details">
                    <div class="details-loading" *ngIf="unitTest.loading">
                        <mat-spinner [diameter]="22"></mat-spinner>
                    </div>
                    <div
                        class="details-status"
                        *ngIf="unitTest.unitTestResponse && !unitTest.loading"
                        [ngClass]="{
                            error: unitTest.unitTestResponse?.invalid,
                            success: !unitTest.unitTestResponse?.invalid
                        }"
                    >
                        <mat-icon *ngIf="unitTest.unitTestResponse?.invalid">close</mat-icon>
                        <mat-icon *ngIf="!unitTest.unitTestResponse?.invalid">check</mat-icon>
                    </div>
                    <div class="details-title">
                        {{ unitTest.title }}
                        <span
                            *ngIf="unitTest.loading"
                            [innerHTML]="'OVERVIEW.UNIT_TESTS.TEST.TITLE.LOADING' | translate"
                        ></span>
                    </div>
                </div>
                <div class="unit-test-action">
                    <button
                        mat-raised-button
                        color="primary"
                        [disabled]="disableTrigger"
                        (click)="startSingleUnitTest(unitTest.documentId, unitTest.latestRevision)"
                    >
                        <mat-icon>start</mat-icon>
                    </button>
                </div>
            </div>
            <div class="unit-test-response" *ngIf="unitTest.unitTestResponse?.invalid && !unitTest.loading">
                <div class="response-delta" *ngFor="let delta of unitTest.unitTestResponse?.delta">
                    <div class="delta-line">
                        {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.TITLE.' + delta.line.toUpperCase() | translate }}
                    </div>
                    <div class="delta-added" *ngIf="delta.added.length">
                        <div class="added-title">
                            {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.ADDED' | translate }}
                        </div>
                        <ul class="added-content" *ngFor="let added of delta.added">
                            <li>
                                {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.MATERIAL' | translate }} {{ added.material }}
                                {{ added.productDescription }}
                            </li>
                            <li>{{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.QUANTITY' | translate }} {{ added.quantity }}</li>
                        </ul>
                    </div>
                    <div class="delta-modified" *ngIf="delta.modified.length">
                        <div class="added-title">
                            {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.MODIFIED' | translate }}
                        </div>
                        <ul class="added-content" *ngFor="let modified of delta.modified">
                            <li>
                                {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.MATERIAL' | translate }} {{ modified.material }}
                                {{ modified.productDescription }}
                            </li>
                            <li>
                                {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.IS_QUANTITY' | translate }}
                                {{ modified.oldQuantity }}
                            </li>
                            <li>
                                {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.SHOULD_QUANTITY' | translate }}
                                {{ modified.quantity }}
                            </li>
                        </ul>
                    </div>
                    <div class="delta-removed" *ngIf="delta.removed.length">
                        <div class="removed-title">{{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.REMOVED' | translate }}</div>
                        <ul class="removed-content" *ngFor="let removed of delta.removed">
                            <li>
                                {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.MATERIAL' | translate }}
                                {{ removed.material }} {{ removed.productDescription }}
                            </li>
                            <li>
                                {{ 'OVERVIEW.UNIT_TESTS.TEST.RESPONSE.QUANTITY' | translate }} {{ removed.quantity }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
