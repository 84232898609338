import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestCheckResult, QuestValueChange } from '@vi/quest';
import { QuestPartReset } from '@vi/quest/lib/quest-part-reset.interface';
import { Observable, of, Subject } from 'rxjs';
import { concatMap, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../services/app.service';
import { ConfigurationResultService } from '../../services/configuration-result.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsService } from '../../services/products.service';
import { LineType, MaterialInfo, Quote, QuoteAssignment, QuoteService } from '../../services/quote.service';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { openConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { CustomerSelection } from '../customer-selection/customer-selection.component';
import { LoadingOverlayComponent } from '../loading-overlay/loading-overlay.component';
import { PriceSummaryDataService } from '../price-summary/price-summary.data.service';
import { ProductListService } from '../product-list/product-list.service';
import { ConfigitConfigurationAndProducts } from './configit-quest-adapter/configit-types.interface';
import { PackerConfigitQuestAdapterService } from './configit-quest-adapter/packer-configit-quest-adapter.service';

const PERFORMANCE_PART_ID = 'Leistungsuebersicht';

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private dialogRef: MatDialogRef<LoadingOverlayComponent>;
    private selectedCustomer: CustomerSelection = { name: '', number: '' };
    private isPriceSummaryLoading = false;

    private showInitialModel: boolean;

    public displayWaitMessage = false;
    public displayProvideProjectIdMsg = false;
    public displaySelectCustomerMsg = false;
    public displayProvideTitleMsg = false;

    public preselectPart = 0;

    public quest$ = new Subject<Quest>();
    public currentModel: Quest;

    @Input()
    public quickRef: string;
    @Input()
    public documentId: string;
    @Input()
    public latestRevision: string;
    @Input()
    public title: string;
    @Input()
    public quotationRequestId: string;
    @Input()
    public prefilled: boolean;

    @Output()
    public isReady = new EventEmitter<boolean>();

    @Output()
    public isComplete = new EventEmitter<boolean>();

    @Output()
    // sorry not sorry
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onSubmit = new EventEmitter<void>();

    constructor(
        private quests: PackerConfigitQuestAdapterService,
        private router: Router,
        private quoteService: QuoteService,
        private selectedCustomerService: SelectedCustomerService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private productListService: ProductListService,
        private productService: ProductsService,
        private dialog: MatDialog,
        private appService: AppService,
        private permissionService: PermissionService,
        private priceSummaryDataService: PriceSummaryDataService,
        private configurationResultService: ConfigurationResultService
    ) {}

    public ngOnInit() {
        this.configurationResultService.configuration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        this.loadQuote();
        this.subscribeToSelectedCustomer$();
        this.subscribeToPriceSummaryLoading$();
        this.subscribeToQuest();
    }

    public page(): void {}

    public reset(event: QuestPartReset) {
        const title = this.translateService.instant('CONFIGURATION.DIALOG.RESET.TITLE');
        const question = this.translateService.instant('CONFIGURATION.DIALOG.RESET.MESSAGE', {
            page: event.part.title,
        });
        const yes = this.translateService.instant('CONFIGURATION.DIALOG.RESET.CONFIRM');
        const no = this.translateService.instant('CONFIGURATION.DIALOG.RESET.CANCEL');
        openConfirmationDialog(this.dialog, title, question, yes, no, (confirmed) => {
            if (confirmed) {
                this.openDialog();
                this.quests
                    .reset(event)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((model: Quest) => {
                        this.setModel(model);
                        this.quoteService.updateQuoteAssignments(
                            model.original.assignments,
                            model.original.template.name
                        );
                        this.closeDialog();
                    });
            } else {
                // set current model as otherwise quest will stay in "checking" state
                this.setModel(this.currentModel);
            }
        });
    }

    public check(changed: QuestValueChange) {
        this.quests
            .check(changed)
            .pipe(map((result: QuestCheckResult) => result.model))
            .subscribe(
                (model: Quest) => {
                    this.setModel(model);
                    this.quoteService.updateQuoteAssignments(model.original.assignments, model.original.template.name);
                    if (this.showInitialModel && this.checkComplete(model)) {
                        // switch to the secondary model as soon as the primary model is complete
                        this.switchToSecondaryModel(model);
                    }
                },
                () => {
                    this.onError('CONFIGURATION.SNACK_BAR.UPDATE_VALUES.ERROR');
                }
            );
    }

    private switchToModel(quest: Quest, material: string, lineType: LineType) {
        // copy all assignments from Miscellaneous.PreSelection to the new model as user assignment
        const copyAssignments: QuoteAssignment[] = quest.original.assignments
            .filter((a) => a.variableName.startsWith(environment.quest.preselectionVariable))
            .map((a) => ({ ...a, isUserAssignment: true }));
        this.openDialog();
        this.quoteService
            .getQuoteSaveObservable()
            .pipe(
                concatMap(() => this.quests.submit(quest)),
                concatMap(() => this.productService.getMaterialInfos([material])),
                concatMap((materialInfos: MaterialInfo[]) =>
                    this.quoteService.createAdditionalLine(materialInfos[0], lineType, copyAssignments)
                )
            )
            .subscribe(() => this.loadQuote(lineType));
    }

    private switchToSecondaryModel(quest: Quest) {
        const material = quest.original.assignments
            .find((a) => a.variableName === environment.quest.modelSelectionVariable)
            .valueName.split(';')[0];
        this.switchToModel(quest, material, LineType.secondary);
    }

    public submit(submittedModel?: Quest | Promise<Quest>) {
        const model: Quest = submittedModel ? <Quest>submittedModel : this.currentModel;

        this.onSubmit.emit();

        this.displayWaitMessage = false;
        // Display error if there is no title or project id
        this.displayProvideTitleMsg = !this.title;
        this.displayProvideProjectIdMsg = !this.quoteService.getCurrentQuote()?.projectId;

        // when user is an employee he must first select a customer that the quote will be assigned to
        if (!this.selectedCustomer.number && this.permissionService.isUserAnEmployee) {
            this.displaySelectCustomerMsg = true;
        }

        // Don't submit when errors are displayed
        if (this.displayProvideTitleMsg || this.displayProvideProjectIdMsg || this.displaySelectCustomerMsg) {
            return;
        }

        // wait till products and price finishes loading
        if (this.productListService.isProductListLoading$.value || this.isPriceSummaryLoading) {
            this.displayWaitMessage = true;

            setTimeout(() => {
                this.submit(model);
            }, 2000);
            return;
        }

        this.openDialog();

        this.quests
            .getEnergyLabelFromConfiguration(model)
            .pipe(
                switchMap((energyLabelHash) => {
                    if (energyLabelHash) {
                        return this.quoteService.updateQuoteProperty('energyLabelHash', energyLabelHash);
                    }
                    return of(null);
                })
            )
            .subscribe(
                () => {
                    this.closeDialog();
                    this.navigateToSummaryPage();
                },
                () => {
                    this.onError('CONFIGURATION.SNACK_BAR.SAVE_CONFIGURATION.ERROR');
                    this.closeDialog();
                }
            );
    }

    private checkComplete(model: Quest): boolean {
        const firstIncomplete = model.parts.findIndex((p) => !p.valid);
        return firstIncomplete === -1;
    }

    private subscribeToQuest() {
        this.quest$.asObservable().subscribe((model: Quest) => {
            this.currentModel = model;
            this.isComplete.next(this.checkComplete(model));
        });
    }

    protected setModel(model: Quest): void {
        const performancePart = model.parts.find((p) => p.id === PERFORMANCE_PART_ID);
        model.parts = model.parts.filter((p) => p.id !== PERFORMANCE_PART_ID);
        if (performancePart) {
            this.configurationResultService.performance$.next(performancePart);
        }
        this.quest$.next(model);
        if (this.showInitialModel) {
            return;
        }
    }

    private onError(translationKey: string) {
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
            isFailure: true,
        });
    }

    private openDialog(): void {
        if (!this.dialogRef) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.width = '250px';
            dialogConfig.scrollStrategy = new NoopScrollStrategy();
            this.dialogRef = this.dialog.open(LoadingOverlayComponent, dialogConfig);
        }
    }

    private closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    private subscribeToPriceSummaryLoading$() {
        this.priceSummaryDataService.isPriceSummaryLoading$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isLoading) => {
                this.isPriceSummaryLoading = isLoading.value;
            });
    }

    private loadQuote(lineType?: LineType) {
        this.quoteService
            .fetchQuote({ documentId: this.documentId, latestRevision: this.latestRevision })
            .subscribe((quote) => this.setupQuest(quote, lineType));
    }

    private setupQuest(quote: Quote, lineType?: LineType) {
        if (quote.quote.status.toLowerCase() !== 'new') {
            this.router.navigate(['next-steps'], {
                queryParams: {
                    documentId: this.documentId,
                    latestRevision: this.latestRevision,
                    sapDocumentId: quote.quote.salesDocumentNumber,
                    printType: quote.printType,
                },
            });
        }
        const primaryLine = this.quoteService.getPrimaryLine(quote.quote);
        const secondaryLine = this.quoteService.getSecondaryLine(quote.quote);
        this.showInitialModel = environment.quest.modelSelectionVariable && !secondaryLine;
        const quoteLine = lineType && this.quoteService.getLineByType(quote.quote, lineType);
        const line = quoteLine || secondaryLine || primaryLine;
        const material = line?.variantCode || environment.quest.project;

        // always go to first part
        this.preselectPart = 0;

        this.quests
            .getWithConfiguration(
                material,
                <Observable<ConfigitConfigurationAndProducts>>(
                    this.configurationResultService.getConfigurationForQuoteLine(line)
                )
            )
            .subscribe(
                (model) => {
                    if (!model || !model.original) {
                        // if model is NULL skip all operations
                        return;
                    }

                    this.setModel(model);
                    this.isReady.emit(true);
                    this.closeDialog();
                },
                () => {
                    this.onError('CONFIGURATION.SNACK_BAR.FETCH_CONFIGURATION.ERROR');
                    this.closeDialog();
                }
            );
    }
    private subscribeToSelectedCustomer$() {
        this.selectedCustomerService.selectedCustomer$
            .pipe(takeUntil(this.ngUnsubscribe), filter(Boolean))
            .subscribe((customer: CustomerSelection) => {
                this.selectedCustomer = customer;
                this.displaySelectCustomerMsg =
                    this.selectedCustomer.number === undefined && this.permissionService.isUserAnEmployee;
            });
    }

    private navigateToSummaryPage() {
        const salesforceId = this.appService.salesforceId$.value;
        const quotationRequestId = this.quotationRequestId;
        this.router.navigate(['summary'], {
            queryParams: {
                quickRef: this.quickRef,
                documentId: this.documentId,
                latestRevision: this.latestRevision,
                title: this.title,
                ...(salesforceId && { salesforceId }),
                ...(quotationRequestId && { quotationRequestId }),
            },
        });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
