import { appVersion } from './app-version';
import { AppConfiguration } from './AppConfiguration';

export const environment: AppConfiguration = {
    production: true,
    instana: true,
    version: appVersion,
    env: 'production',
    app: {
        defaultLanguage: 'de-DE',
        supportedLanguages: [
            { locale: 'de-DE', flag: 'de', salesOrg: '0500' },
            { locale: 'en-DE', flag: 'de', salesOrg: '0500' },
        ],
        priceAdvantageNotRelevantSalesOrgs: ['0600', '*'],
        roles: {
            admin: 'Quoteassistpro.Admin',
            customer: 'Quoteassistpro.Default',
        },
    },
    quest: {
        context: 'quest',
        dropoutGroup: 'Dropout',
        project: 'QuoteAssistPro',
        modelSelectionVariable: 'Anonymous.ProductGroup',
        preselectionVariable: 'Miscellaneous.PreSelection',
    },
    configit: {
        url: 'https://api.viessmann.com/backend/modular-energy-center/v1',
        languages: ['de_DE'],
        salesAreaName: 'Germany',
        plant: '0500',
        usage: '5',
    },
    offline: {
        enabled: false,
        keyword: 'offline=1',
        baseUrl: 'https://modular-energy-center.viessmann.com/assets/offline/',
    },
    http: {
        baseUrl: 'https://api.viessmann.com/backend/modular-energy-center/v1/',
        baseClientUrl: 'https://modular-energy-center.viessmann.com/',
        clientUrl: 'https://modular-energy-center.viessmann.com/overview',
        viBooksBaseUrl: 'https://vibooks.viessmann.com',
        serviceConfiguratorBaseUrl: 'https://service-configurator.viessmann.com',
        users: 'https://api.viessmann.com/users/v1/users/me?sections=identity&sections=sales&sections=roles',
        translations:
            'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/639afeeb324ba95b71f9224e91925293/locales/',
        dswTranslations:
            'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/',
        statusPage: 'https://api.viessmann.com/status/v1/apps/rhwny5sryyyt/summary',
        configuration: 'https://api.viessmann.com/backend/configuration/v1/',
        pim: 'https://api.viessmann.com/backend/pim/v1/',
        companyUrl: 'https://api.viessmann.com/company/v3/companies/',
        quotation: 'https://api.viessmann.com/quotation/v1/',
    },
    globalHeader: {
        baseUrl: 'https://api.viessmann.com',
        accountAppUrl: 'https://account.viessmann.com',
    },
    saml: {
        appId: 'modular-energy-center',
        baseUrl: 'https://api.viessmann.com',
    },
    file: {
        uploadUrl: 'upload',
        downloadUrl: 'download',
    },
    feature: {
        salesorgSwitcher: false,
        allowFailingPriceCalc: false,
        voucherInput: false,
    },
    requireSalesOrg: true,
};
