import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ConfigurationResultService } from '../../services/configuration-result.service';
import { Product, ProductsService } from '../../services/products.service';
import { Quote, QuoteService } from '../../services/quote.service';
import { ConfigitConfigurationAndProducts } from '../questionnaire/configit-quest-adapter/configit-types.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductListService {
    public isProductListLoading$ = new BehaviorSubject<boolean>(false);
    public productList$: Observable<Product[]>;

    constructor(
        private quoteService: QuoteService,
        private configurationResultService: ConfigurationResultService,
        private productService: ProductsService
    ) {
        this.productList$ = combineLatest([
            this.quoteService
                .getCurrentQuoteObservable()
                .pipe(
                    distinctUntilChanged(
                        (prev, next) =>
                            JSON.stringify(prev?.quote && this.quoteService.getProductLines(prev.quote)) ===
                            JSON.stringify(this.quoteService.getProductLines(next.quote))
                    )
                ),
            this.configurationResultService.configuration$.pipe(
                distinctUntilChanged(
                    (prev, current) => JSON.stringify(prev?.bomItems) === JSON.stringify(current?.bomItems)
                )
            ),
        ]).pipe(
            tap(() => this.isProductListLoading$.next(true)),
            switchMap(([quote, configuration]) => {
                if (!quote || !configuration) {
                    return of(undefined);
                }
                return forkJoin([this.getProductsFromConfiguration(configuration), this.getExternalProducts(quote)]);
            }),
            filter(Boolean),
            map(([productsFromQuote, externalProducts]) => [...productsFromQuote, ...externalProducts]),
            tap(() => this.isProductListLoading$.next(false)),
            shareReplay(1)
        );
    }

    private getExternalProducts(quote: Quote): Observable<Product[]> {
        const lines = this.quoteService.getProductLines(quote.quote);
        const materials = lines.map((line) => ({ material: line.variantCode, quantity: line.quantity }));
        return materials.length
            ? this.productService.getProducts(materials).pipe(
                  map((products) =>
                      products.map((product) => {
                          const line = lines.find((l) => l.variantCode === product.material);
                          const optional = this.quoteService.isProductOptional(quote.quote, line);
                          return {
                              ...product,
                              translation: line.description.serializedTranslations.reduce(
                                  (obj, ts) => ({ ...obj, [ts.language]: ts.translation }),
                                  {}
                              ),
                              category: 'EXTERNAL',
                              optional,
                          };
                      })
                  )
              )
            : of([]);
    }

    private getProductsFromConfiguration(configuration: ConfigitConfigurationAndProducts): Observable<Product[]> {
        const products = configuration.bomItems.filter((item) => item.componentQuantity > 0);

        if (products.length === 0) {
            return of([]);
        }

        this.quoteService.pruneSubLines(products.map((p) => p.material));

        return this.productService.getProducts(
            products.map((p) => ({ material: p.material, quantity: p.componentQuantity, bomItemId: p.itemId }))
        );
    }
}
