<app-main-content content>
    <div main fxLayout="row" fxLayoutGap="10px">
        <button mat-icon-button routerLink="/overview">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <app-breadcrumbs
            formerPage="{{ 'COMMON.BREADCRUMBS.OVERVIEW' | translate }}"
            currentPage="{{ 'COMMON.BREADCRUMBS.CONFIGURATION' | translate }}"
            [navigateTo]="'/overview'"
        ></app-breadcrumbs>
    </div>
    <div class="top-container" main fxLayout="column">
        <h2 class="header-title">{{ 'CONFIGURATION.TITLE' | translate }}</h2>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div fxLayout="column" class="offer-title-container">
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" *ngIf="projectIdControl">
                    <span class="validation">
                        <mat-spinner
                            [diameter]="20"
                            color="accent"
                            *ngIf="projectIdControl.value && projectIdControl.pending"
                        ></mat-spinner>
                        <!-- placeholder for empty pristine state -->
                        <div *ngIf="!projectIdControl.value">&nbsp;</div>
                        <mat-icon color="success" *ngIf="projectIdControl.value && projectIdControl.valid"
                            >check</mat-icon
                        >
                        <mat-icon color="error" *ngIf="projectIdControl.value && projectIdControl.invalid"
                            >close</mat-icon
                        >
                    </span>
                    <span class="info">
                        <vi-quest-hint
                            [model]="{ text: 'CONFIGURATION.PROJECT_ID.TOOLTIP' | translate, important: false }"
                        >
                        </vi-quest-hint>
                    </span>
                    <mat-form-field fxFlex class="offer-field">
                        <mat-label>{{ 'CONFIGURATION.PROJECT_ID.PLACEHOLDER' | translate }}</mat-label>
                        <input
                            matInput
                            [formControl]="projectIdControl"
                            (keyup.enter)="projectIdInput.blur()"
                            #projectIdInput
                            autocomplete="organization"
                        />
                        <mat-icon matPrefix class="assignment-icon">edit</mat-icon>
                        <button *ngIf="projectIdControl.value" mat-icon-button matSuffix class="enter">
                            <mat-icon>subdirectory_arrow_left</mat-icon>
                        </button>
                        <mat-error *ngIf="projectIdControl.hasError('required')">{{
                            'CONFIGURATION.VALIDATION.PROJECT_ID.REQUIRED' | translate
                        }}</mat-error>
                        <mat-error *ngIf="projectIdControl.hasError('invalid')">
                            {{ 'CONFIGURATION.VALIDATION.PROJECT_ID.INVALID' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start bottom">
                    <span data-at-quickref class="quickref">{{ quickRef }}</span>
                    <mat-form-field fxFlex class="offer-field" (click)="onOfferTitleClick()">
                        <mat-label>{{ 'CONFIGURATION.OFFER_TITLE_PLACEHOLDER' | translate }}</mat-label>
                        <input
                            data-at-quote-title
                            #offerTitleInput
                            matInput
                            maxlength="100"
                            [(ngModel)]="offerTitle"
                            [errorStateMatcher]="errorOnEmptyMatcher"
                            (blur)="onOfferTitleBlur()"
                        />
                        <mat-icon matPrefix class="assignment-icon">edit</mat-icon>
                        <button *ngIf="offerTitle" mat-icon-button matSuffix class="enter">
                            <mat-icon>subdirectory_arrow_left</mat-icon>
                        </button>
                        <mat-hint *ngIf="!isOfferTitleDisabled" align="end">{{ offerTitle.length }} / 100</mat-hint>
                        <mat-error>
                            {{ 'CONFIGURATION.VALIDATION.PLEASE_PROVIDE_TITLE_MESSAGE' | translate }}</mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
            <app-customer-selection *ngIf="permissionService.isUserAnEmployee"></app-customer-selection>
        </div>
    </div>
    <div main #contentContainer class="content-container" [class.hidden]="!isQuestFormReady">
        <app-card
            class="quest-lib-container"
            [class.quest-lib-container-min-height]="isQuestFormReady"
            [isFooter]="false"
            [isHeader]="false"
        >
            <div body>
                <app-questionnaire
                    #questionaire
                    [quickRef]="quickRef"
                    [documentId]="documentId"
                    [latestRevision]="latestRevision"
                    [title]="offerTitle"
                    [quotationRequestId]="quotationRequestId"
                    [prefilled]="prefilled"
                    (isReady)="onQuestFormReady()"
                    (isComplete)="onQuestFormComplete($event)"
                    (onSubmit)="onQuestSubmit()"
                ></app-questionnaire>
            </div>
        </app-card>
        <div class="outer-container" fxLayout="column" *ngIf="isQuestFormReady">
            <div class="sticker" fxLayout="column">
                <app-performance-overview></app-performance-overview>
                <app-price-summary></app-price-summary>
                <app-product-list></app-product-list>
                <button
                    class="next"
                    mat-raised-button
                    type="button"
                    color="primary"
                    [disabled]="!isQuestFormComplete"
                    (click)="submit()"
                >
                    {{ 'CONFIGURATION.SUMMARY' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div main *ngIf="!isQuestFormReady">
        <app-loader [message]="loaderTextKey | translate"></app-loader>
    </div>
</app-main-content>
