<div fxLayout="column" fxLayoutAlign="start center">
    <ul fxLayout="row" class="content-box" *ngIf="showTemplatesTab">
        <li
            routerLink="/overview/quotes"
            routerLinkActive="active"
            (click)="selectedTab = 'offers'"
            [class.active]="selectedTab === 'offers'"
        >
            {{ 'OVERVIEW.OFFERS.TITLE' | translate }}
        </li>
        <li
            routerLink="/overview/templates"
            routerLinkActive="active"
            (click)="selectedTab = 'templates'"
            [class.active]="selectedTab === 'templates'"
        >
            {{ 'OVERVIEW.TEMPLATES.TITLE' | translate }}
        </li>
        <li
            routerLink="/overview/unit-tests"
            routerLinkActive="active"
            (click)="selectedTab = 'unit-tests'"
            [class.active]="selectedTab === 'unit-tests'"
            *ngIf="isAdmin"
        >
            {{ 'OVERVIEW.TEMPLATES.UNIT_TESTS' | translate }}
        </li>
    </ul>

    <div class="content-full">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="content-box title-header">
            <ng-container *ngIf="selectedTab === 'offers'">
                <h2 *ngIf="isPartnerMode">{{ 'OVERVIEW.TITLE_PARTNER_MODE' | translate }}</h2>
                <h2 *ngIf="!isPartnerMode">{{ 'OVERVIEW.OFFERS.TITLE' | translate }}</h2>
                <div
                    fxLayout="row wrap"
                    fxLayoutAlign="space-between center"
                    fxFlex.lt-md="300px"
                    class="button-container"
                >
                    <button mat-raised-button color="primary" (click)="createNewOffer()">
                        <mat-icon>create</mat-icon>
                        {{ 'OVERVIEW.BUTTON.CREATE_NEW' | translate }}
                    </button>
                    <app-customer-selection
                        [partnerMode]="true"
                        *ngIf="isEmployee"
                        buttonText="OVERVIEW.BUTTON.PARTNER_MODE"
                        (customerChanged)="onCustomerSelected()"
                    ></app-customer-selection>
                </div>
            </ng-container>
            <h2 *ngIf="selectedTab === 'templates'">{{ 'OVERVIEW.TEMPLATES.TITLE' | translate }}</h2>
            <h2 *ngIf="selectedTab === 'unit-tests' && isAdmin">{{ 'OVERVIEW.UNIT_TESTS.TITLE' | translate }}</h2>
        </div>
    </div>
    <div fxLayout="column" class="content-box">
        <div class="top-container" fxLayout="column" *ngIf="selectedTab !== 'unit-tests'">
            <app-search-and-filter (offerSearch)="onSearch($event)"></app-search-and-filter>
        </div>

        <app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="loaderText"></app-loader>

        <app-overview-table
            [hidden]="isLoading || selectedTab !== 'offers'"
            [dataSource]="overviewTableDataSource"
            (viewOffer)="onViewOffer($event)"
            (copyOffer)="onCopyOffer($event)"
            (deleteOffer)="onDeleteOffer($event)"
            (pagingError)="onPagingError()"
            (sortError)="onSortError()"
            [isEmployee]="isEmployee"
        ></app-overview-table>

        <app-templates-table
            [hidden]="isLoading || selectedTab !== 'templates'"
            [dataSource]="templatesDataSource"
            (useTemplate)="onUseTemplate($event)"
            (pagingError)="onPagingError()"
            (sortError)="onSortError()"
        ></app-templates-table>

        <app-unit-tests
            [hidden]="isLoading || selectedTab !== 'unit-tests'"
            [unitTestsData]="unitTestsData"
            *ngIf="isAdmin"
        >
        </app-unit-tests>
    </div>
</div>
