<div fxLayout="column">
    <vi-quest
        [model]="quest$"
        (check)="check($event)"
        (partChange)="page()"
        (reset)="reset($event)"
        (submit)="submit($event)"
        [part]="preselectPart"
    ></vi-quest>
    <mat-error *ngIf="displayProvideProjectIdMsg" class="please-select-msg">
        {{ 'CONFIGURATION.VALIDATION.PROJECT_ID.REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="displaySelectCustomerMsg" class="please-select-msg">
        {{ 'CONFIGURATION.CUSTOMER_SEARCH.SELECT_CUSTOMER_VALIDATION_MESSAGE' | translate }}
    </mat-error>
    <mat-error *ngIf="displayProvideTitleMsg" class="please-select-msg">
        {{ 'CONFIGURATION.VALIDATION.PLEASE_PROVIDE_TITLE_MESSAGE' | translate }}
    </mat-error>
    <div class="wait-msg" *ngIf="displayWaitMessage">
        {{ 'CONFIGURATION.SUBMIT.PLEASE_WAIT_MESSAGE' | translate }}
    </div>
</div>
