<app-main-content content>
    <div main fxLayout="row" fxLayoutGap="10px">
        <a mat-button color="primary" [href]="callbackUrl" *ngIf="callbackUrl">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.EXTERNAL_APP.CALLBACK_URL' | translate }}
        </a>
        <ng-container *ngIf="!callbackUrl">
            <button mat-icon-button routerLink="/overview">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <app-breadcrumbs
                formerPage="{{ 'COMMON.BREADCRUMBS.OVERVIEW' | translate }}"
                currentPage="{{ 'COMMON.BREADCRUMBS.NEXT_STEPS' | translate }}"
                [navigateTo]="'/overview'"
            ></app-breadcrumbs>
        </ng-container>
    </div>

    <h2 main class="title">{{ 'NEXT_STEPS.TITLE' | translate }}</h2>

    <div main fxLayout="column" fxLayoutGap="10px">
        <app-documents-selection
            [sapDocumentId]="sapDocumentId"
            [documentId]="documentId"
            [latestRevision]="latestRevision"
            [externalApp]="externalApp"
            [printType]="printType"
        ></app-documents-selection>
    </div>
    <div main fxLayout="row" fxLayoutAlign="end top" fxLayoutGap="10px">
        <a mat-button color="primary" [href]="callbackUrl" *ngIf="callbackUrl">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.EXTERNAL_APP.CALLBACK_URL' | translate }}
        </a>
        <button mat-button color="primary" [routerLink]="['/overview']" *ngIf="!callbackUrl">
            <mat-icon>arrow_back</mat-icon>
            {{ 'NEXT_STEPS.BUTTON.BACK_TO_OVERVIEW' | translate }}
        </button>
        <a mat-raised-button color="primary" target="_blank" [href]="serviceConfiguratorUrl">
            <mat-icon>description</mat-icon>
            {{ 'NEXT_STEPS.BUTTON.SERVICE_CONFIGURATOR' | translate }}
        </a>
    </div>
</app-main-content>
