import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PermissionService } from '../../services/permission.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UnitTestsService } from '../../services/unit-tests.service';

export interface UnitTestResponse {
    documentId: string;
    delta: UnitTestDelta[];
    invalid: boolean;
}

export interface UnitTestDelta {
    line: string;
    added: UnitTestDeltaResponse[];
    removed: UnitTestDeltaResponse[];
    modified: UnitTestDeltaResponse[];
}

export interface Response {
    linesDelta: UnitTestDelta[];
}

export interface UnitTestDeltaResponse {
    id: string;
    material: string;
    quantity: number;
    oldQuantity?: number;
    productDescription?: string;
}

export interface PimProduct {
    _id: string;
    name: string;
    description?: string;
}

@Component({
    selector: 'app-unit-tests',
    templateUrl: './unit-tests.component.html',
    styleUrls: ['./unit-tests.component.scss'],
})
export class UnitTestsComponent implements OnInit {
    public unitTests = [];
    @Input() public unitTestsData;
    public disableTrigger = false;

    constructor(
        private unitTestsService: UnitTestsService,
        private permissionService: PermissionService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.unitTestsData.subscribe((data) => (this.unitTests = data));
    }

    public startAllUnitTests() {
        this.unitTests.forEach((unitTest) => {
            this.startSingleUnitTest(unitTest.documentId, unitTest.latestRevision);
        });
    }

    public startSingleUnitTest(documentId: string, revision: number) {
        this.disableTrigger = true;
        this.unitTests.find((d) => d.documentId === documentId).loading = true;
        this.unitTestsService
            .startSingleUnitTest(documentId, revision)
            .pipe(
                switchMap((response: Response) => {
                    const unitTestResponse: UnitTestResponse = {
                        documentId: documentId,
                        delta: response.linesDelta.map((lineDelta: UnitTestDelta) => ({
                            line: lineDelta.line,
                            added: lineDelta.added,
                            removed: lineDelta.removed,
                            modified: lineDelta.modified,
                        })),
                        invalid: false,
                    };
                    if (
                        unitTestResponse.delta.some(
                            (deltaItem) =>
                                deltaItem.added.length > 0 ||
                                deltaItem.removed.length > 0 ||
                                deltaItem.modified.length > 0
                        )
                    ) {
                        unitTestResponse.invalid = true;
                    }
                    this.unitTests.forEach((unitTest) => {
                        if (unitTest.documentId === documentId) {
                            unitTest.unitTestResponse = unitTestResponse;
                        }
                    });

                    if (unitTestResponse.invalid) {
                        return this.unitTestsService
                            .getPimProducts(this.extractUnitTestMaterials(unitTestResponse))
                            .pipe(
                                map((PimProducts: PimProduct[]) => {
                                    this.mapPimProductToUnitTestResponse(unitTestResponse, PimProducts);
                                })
                            );
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe(
                () => {
                    this.unitTests.find((d) => d.documentId === documentId).loading = false;
                    this.disableTrigger = false;
                },
                () => this.onError('OVERVIEW.UNIT_TESTS.SNACK_BAR.EXECZTE_UNIT_TEST.FAILURE')
            );
    }

    private extractUnitTestMaterials(unitTest: UnitTestResponse) {
        return unitTest.delta.flatMap((delta) => {
            const { added, removed, modified } = delta;
            return [...added, ...removed, ...modified].map((d) => d.material);
        });
    }

    private mapPimProductToUnitTestResponse(unitTest: UnitTestResponse, PimProducts: PimProduct[]) {
        return {
            delta: unitTest.delta.map((delta) => {
                const expand = (d: UnitTestDeltaResponse) => {
                    const productDescription = PimProducts.find((m) => m.name === d.material)?.description;
                    d.productDescription = productDescription;
                };
                return {
                    added: delta.added.map(expand),
                    removed: delta.removed.map(expand),
                    modified: delta.modified.map(expand),
                };
            }),
        };
    }

    private onError(translationKey: string) {
        if (this.permissionService.isUserAuthorized$.value) {
            setTimeout(() => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant(translationKey),
                    isFailure: true,
                });
            }, 200);
        }
    }
}
