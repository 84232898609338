import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductsService } from '../../services/products.service';
import { ProductListService } from './product-list.service';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    public productListViewModel: any[];

    constructor(public productListService: ProductListService, private productService: ProductsService) {}

    public ngOnInit() {
        this.productListService.productList$.pipe(takeUntil(this.unsubscribe$)).subscribe((products) => {
            this.productListViewModel = this.productService.getProductListViewModel(products);
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
