<div fxLayout="column">
    <div fxFlex="64px" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="10px" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="left center">
            <app-modular-energy-center-logo
                [externalApp]="appService.appTitle$ | async"
            ></app-modular-energy-center-logo>
        </div>
        <div fxFlex>
            <div fxLayout="row" fxLayoutAlign="end center">
                <div>
                    <span>{{ username }}</span
                    ><span *ngIf="!permissionService.isUserAnEmployee"> - {{ userId }}</span>
                </div>
                <app-salesorg-dropdown
                    [availableOptions]="appService.availableLanguages$ | async"
                    [selectedLanguage]="appService.getLocation()"
                    (languageChanged)="onLanguageChanged($event)"
                    (salesOrgChanged)="onSalesOrgChanged($event)"
                ></app-salesorg-dropdown>
                <button
                    mat-button
                    color="primary"
                    class="header-button bordered"
                    (click)="openFeedbackDialog()"
                    *ngIf="(appService.appTitle$ | async) === ''"
                >
                    <mat-icon>mail</mat-icon>
                    <span fxHide.lt-lg>{{ 'COMMON.BUTTON.FEEDBACK' | translate }}</span>
                </button>
                <a mat-icon-button href="https://support.viessmann.com/?page=1&pageSize=8" target="_blank">
                    <mat-icon>help_outline</mat-icon>
                </a>
                <vih-app-switcher></vih-app-switcher>
                <vih-login
                    [appManagesAuthentication]="true"
                    [authenticated]="authenticated$"
                    (logoutEvent)="authService.onLogout()"
                ></vih-login>
            </div>
        </div>
    </div>
</div>
