import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
@Component({
    selector: 'app-feature-banner',
    templateUrl: './feature-banner.component.html',
    styleUrls: ['./feature-banner.component.scss'],
})
export class FeatureBannerComponent {
    constructor(private translationService: TranslateService) {}
    featureMessage$ = combineLatest([
        this.translationService.onLangChange.pipe(startWith({})),
        this.translationService.getTranslation(this.translationService.currentLang).pipe(startWith({})),
    ]).pipe(
        switchMap(() => this.translationService.get('COMMON')),
        map((translation) => translation['FEATURE_BANNER']?.MESSAGE)
    );
}
