import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuestPart } from '@vi/quest';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfigurationResultService } from '../../services/configuration-result.service';

@Component({
    selector: 'app-performance-overview',
    templateUrl: './performance-overview.component.html',
    styleUrls: ['./performance-overview.component.scss'],
})
export class PerformanceOverviewComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private configurationResultService: ConfigurationResultService) {}

    public part: QuestPart;

    ngOnInit(): void {
        this.configurationResultService.performance$
            .pipe(takeUntil(this.unsubscribe$), filter(Boolean))
            .subscribe((performance: QuestPart) => {
                this.part = performance;
            });
    }

    createClassName(className: string) {
        return className.replace('Leistungsuebersicht.', '');
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
