/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';

export type Status = 'major_outage' | 'under_maintenance' | 'partial_outage' | 'degraded_performance' | 'operational';

export interface PlannedMaintenance {
    start_date: string;
    end_date: string;
    remind_from: string;
    status: string;
}

export interface StatusSummary {
    id: string;
    name: string;
    status: Status;
    next_maintenance?: PlannedMaintenance;
}

@Injectable({
    providedIn: 'root',
})
export class StatusPageService {
    private currentStatus$: BehaviorSubject<StatusSummary> = new BehaviorSubject<StatusSummary>(undefined);
    constructor(private httpService: HttpService, private router: Router) {}

    public fetchStatus(): Observable<StatusSummary> {
        return this.httpService
            .get<StatusSummary>(environment.http.statusPage)
            .pipe(tap((status) => this.currentStatus$.next(status)));
    }

    public getStatus(): Observable<StatusSummary> {
        return this.currentStatus$;
    }

    /**
     * Check if there is an outage or maintenance ongoing and if so, redirect to maintenance page.
     * Returns whether there is currently a downtime.
     */
    public checkForDowntime(): Observable<boolean> {
        // backdoor for maintenance windows that are planned for bigger deployments (e.g. configit update).
        // during a prod deployment with downtime, set this local storage key to bypass the maintenance check and do some smoke tests.
        const ignoreFlag = localStorage.getItem('ignore_statuspage');
        if (ignoreFlag === 'true') {
            return of(false);
        }
        return this.fetchStatus().pipe(
            map((status) => {
                if (['major_outage', 'under_maintenance'].includes(status.status)) {
                    this.router.navigate(['maintenance'], {
                        state: status,
                    });
                    return true;
                }
                return false;
            }),
            catchError(() => of(true))
        );
    }
}
