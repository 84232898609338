<a fxLayout="row" fxLayoutAlign="start center" routerLink="/" class="app-title" fxLayoutGap="4px" *ngIf="externalApp">
    <img src="../../../../assets/images/viessmann-white.svg" class="logo-img" />
    <div fxHide.lt-sm fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>&#65381;</span>
        <span class="title d-none d-sm-block">{{ externalApp }}</span>
    </div>
</a>
<a
    fxLayout="row"
    fxLayoutAlign="start center"
    [routerLink]="['/overview']"
    class="app-title"
    fxLayoutGap="4px"
    *ngIf="!externalApp"
>
    <img src="../../../../assets/images/viessmann-white.svg" class="logo-img" />
    <div fxHide.lt-sm fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>&#65381;</span>
        <span class="title d-none d-sm-block">{{ 'HEADER.APP-TITLE' | translate }}</span>
    </div>
</a>
