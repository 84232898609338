import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppService } from 'src/app/_shared/services/app.service';
import { environment } from '../../../../../environments/environment';
import { ApiBase } from './api.base';
import {
    ConfigitAssignmentResp,
    ConfigitConfiguration,
    ConfigitConfigurationResp,
    ConfigitEnvSettings,
    ConfigitSettings,
    ConfigitTemplate,
    ConfigitTemplateResp,
} from './configit-types.interface';

/**
 * Configit specialized service
 */

@Injectable()
export class ConfigitApiService extends ApiBase {
    protected baseUrl = environment.configit.url;

    constructor(protected http: HttpClient, protected translate: TranslateService, protected appService: AppService) {
        super(http, translate);
    }

    public fetchConfigitSettings(): Observable<ConfigitSettings> {
        return this.request('get', '/configuration/settings').pipe(
            tap((settings) => this.appService.configuration$.next(settings))
        );
    }

    public config(): ConfigitEnvSettings {
        const conf = JSON.parse(JSON.stringify(environment.configit)); // deep clone
        const language = this.appService.getLanguage().replace('-', '_');
        conf.languages = [language];
        conf.salesAreaName = this.appService.getSalesAreaName();
        conf.salesAreaId = this.appService.getSalesAreaId();
        conf.plant = this.appService.getSalesOrg();
        return conf;
    }

    /**
     * Gets template
     *
     * @param data Additional params replacing defaults form environment.configit
     * @returns Template structure (first)
     */
    public getTemplate(data: any = {}): Observable<ConfigitTemplate> {
        const body = this.templateParams(data);

        return this.http
            .request('post', `${environment.http.configuration}configit/getMaterialTemplateData`, { body })
            .pipe(map((resp: ConfigitTemplateResp) => resp.templates[0]));
    }

    /**
     * Gets configuration
     *
     * @param data Additional params replacing defaults from environment.configit
     * @param assignments Initial assignments
     * @returns Configuration structure (extracted from root)
     */
    public getConfiguration(data: any = {}, assignments?: any) {
        const body = this.configurationParams(data, assignments);

        return this.http
            .request('post', `${environment.http.configuration}configit/getFromExistingConfiguration`, { body })
            .pipe(
                map((resp: ConfigitConfigurationResp) => ({
                    configuration: resp.root.configuration,
                    bomItems: resp.root.bomItems,
                }))
            );
    }

    /**
     * Submits update value
     *
     * @param name Material name
     * @param variable Variable name
     * @param value Variable value
     * @param existing Existing assignments
     * @param data Additional params replacing defaults form environment.configit
     * @returns Configuration data
     */
    public setAssignment(
        name: string,
        variable: string,
        value: any,
        existing?: any,
        data?: any
    ): Observable<ConfigitConfiguration> {
        const body = this.updateParams(name, variable, value, existing, data);
        return this.http.request('post', `${environment.http.configuration}configit/updateValues`, { body }).pipe(
            map((resp: ConfigitAssignmentResp) => this.checkErrors(variable, value, resp)),
            map((resp: ConfigitAssignmentResp) => resp.bomDeltaConfigurationData.configurationData)
        );
    }

    /**
     * Submits configuration
     *
     * @param material Material name
     * @param assignments Existing assignments
     * @param order Order identifier
     * @param captcha Captcha response
     * @param recommend Recommend flag
     * @returns Configuration structure (extracted from root)
     */
    public submitConfiguration(
        material: string,
        assignments?: any,
        order?: string,
        captcha?: string,
        recommend?: boolean
    ): Observable<ConfigitConfiguration> {
        const data = { material, captcha, order, recommend, submit: 'true' };
        const params = this.configurationParams(data, assignments);

        return this.request('post', '/configuration/submit', params).pipe(
            map((resp: ConfigitConfigurationResp) => resp.root.configuration)
        );
    }

    public getEnergyLabelFromConfiguration(material: string, assignments: any): Observable<string> {
        const data = { material, submit: 'true' };
        const params = this.configurationParams(data, assignments);

        return this.request(
            'post',
            `/configuration/generate-energy-label?language=${this.appService.getLanguageOnly()}&salesOrg=${this.appService.getSalesOrg()}`,
            params
        ).pipe(map((resp: ConfigitConfigurationResp) => resp.energyLabelHash));
    }

    protected checkErrors(variable: string, value: any, resp: ConfigitAssignmentResp): ConfigitAssignmentResp {
        // as error detected, move generic/unknown error to configuration structure
        if (resp.assignmentError) {
            resp.bomDeltaConfigurationData = {
                configurationData: {
                    uiGroupStates: [],
                    // put invalid value to the state as can be consumed by transformer to keep in UI
                    variableStates: [
                        {
                            fullyQualifiedName: variable,
                            invalidMessage: this.translate.instant(`${environment.quest.context}.error.invalid`),
                            invalidValue: value,
                        },
                    ],
                    // but remove from assignments if exists (f.e. previous valid)
                    assignmentsToRemove: [
                        {
                            variableName: variable,
                        },
                    ],
                },
            };
        }

        return resp;
    }

    /**
     * Constructs template get params - combining given params and defaults from config
     *
     */
    protected templateParams(params: ConfigitEnvSettings = {}): any {
        const config = this.config();

        return {
            name: params.material || config.material,
            languages: params.languages || config.languages,
            salesAreaName: params.salesAreaName || config.salesAreaName,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            plant: params.plant || config.plant,
        };
    }

    /**
     * Constructs configuration get params - combining given params and defaults from config
     */
    protected configurationParams(params: ConfigitEnvSettings = {}, assignments: any[] = []): any {
        const config = this.config();
        return {
            // package-packer specific params ///////////////////////////////////////////////
            plant: params.plant || config.plant,
            usage: config.usage,
            /////////////////////////////////////////////////
            name: params.material || config.material,
            languages: params.languages || config.languages,
            salesAreaName: params.salesAreaName || config.salesAreaName,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            rootConfiguration: {
                existingAssignments: assignments,
                materialName: params.material || config.material,
            },
            environment: {
                rootEnvironment: {
                    preselect: params.preselect,
                    submit: params.submit,
                    order: params.order,
                    captcha: params.captcha,
                    recommend: params.recommend && 'true',
                    salesArea: {
                        salesOrganization: params.plant || config.plant,
                        distributionChannel: '01',
                    },
                },
            },
        };
    }

    private getUpdatedValues(
        variable: string,
        value: string | string[],
        assignments: any[]
    ): { added: string[]; removed: string[] } {
        let added = [];
        let removed = [];
        const variableAssignments = assignments.filter((a) => a.variableName === variable);
        if (Array.isArray(value)) {
            // multi value question (e.g. checklist),
            // check if the item was added or removed by comparing with current assignments
            removed = variableAssignments.filter((item) => !value.includes(item.valueName)).map((r) => r.valueName);
            // value array has all selected item ids, only send the latest value,
            // that is, the value that does not occur in any of the current assignments
            added = value.filter((id) => !variableAssignments.some((item) => item.valueName === id));
        } else {
            // normal single value question
            if (value === undefined || value === null || value === '') {
                removed = variableAssignments.map((a) => a.valueName);
            } else {
                added = [value];
            }
        }
        return { added, removed };
    }

    protected updateParams(
        name: string,
        variable: string,
        value: string,
        assignments: any[] = [],
        params: ConfigitEnvSettings = {}
    ) {
        const config = this.config();

        const { added, removed } = this.getUpdatedValues(variable, value, assignments);

        const updatedValues = [
            ...added.map((add) => ({ value: add, updateType: 1 })),
            ...removed.map((rem) => ({ value: rem, updateType: 0 })),
        ];

        return {
            name,
            languages: params.languages || config.languages,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: params.salesAreaName || config.salesAreaName,
            plant: params.plant || config.plant,

            assignment: {
                existingAssignments: assignments,
                itemId: '',
                newAssignment: {
                    action: 'updateValues',
                    assignment: {
                        variableName: variable,
                        updatedValues,
                        isDefault: false,
                    },
                },
            },
            refreshBom: true,
            environment: {
                rootEnvironment: {
                    salesArea: {
                        distributionChannel: '01',
                        salesOrganization: params.plant || config.plant,
                    },
                    order: params.order,
                },
            },
        };
    }

    protected extract(resp: Observable<any>, part = 'data'): Observable<any> {
        return resp.pipe(map((r: any) => r[part]));
    }
}
