import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { AppService, ProductTranslations } from '../../services/app.service';
import { Product, ProductsService } from '../../services/products.service';
import { QuoteService } from '../../services/quote.service';
import { openConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';

const COMMON_COLUMNS = ['image', 'partNumber', 'quantity', 'description', 'materialGroup'];

type ProductFormControlType = {
    optional: FormControl<boolean>;
    quantity: FormControl<number>;
};

type EditableProduct = Product & { formGroup: FormGroup<ProductFormControlType> };

@Component({
    selector: 'app-product-table',
    templateUrl: './product-table.component.html',
    styleUrls: ['./product-table.component.scss'],
})
export class ProductTableComponent implements OnInit {
    constructor(
        private quoteService: QuoteService,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private appService: AppService,
        public productService: ProductsService
    ) {}

    @ViewChild(MatSort)
    public sort: MatSort;

    @Input()
    public dataSource: any;

    @Input()
    public isExternalApp = false;

    public columnsToDisplay: string[];

    public ngOnInit() {
        if (this.dataSource) {
            this.dataSource.sort = this.sort;
        }
        this.columnsToDisplay = this.isExternalApp
            ? [...COMMON_COLUMNS, 'isOptional', 'actions']
            : ['product-group', ...COMMON_COLUMNS];
    }

    public chooseTranslation(productTranslations?: ProductTranslations) {
        return this.appService.chooseTranslation(productTranslations);
    }

    public deleteProduct(product: Product) {
        openConfirmationDialog(
            this.dialog,
            this.translateService.instant('SUMMARY.DIALOG.DELETE_PRODUCT.TITLE'),
            this.translateService.instant('SUMMARY.DIALOG.DELETE_PRODUCT.MESSAGE', { name: product.material }),
            this.translateService.instant('COMMON.DIALOG.CONFIRM'),
            this.translateService.instant('COMMON.DIALOG.CANCEL'),
            (confirmed) => {
                if (confirmed) {
                    this.quoteService.removeProductLine(product.material);
                }
            }
        );
    }

    public changeQuantity(product: EditableProduct) {
        const quantity = product.formGroup.controls.quantity.value;
        this.quoteService.setProductQuantity(product.material, quantity);
    }

    public setOptional(product: EditableProduct) {
        const optional = product.formGroup.controls.optional.value;
        this.quoteService.setProductOptional(product.material, optional);
    }
}
