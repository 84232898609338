import { Injectable } from '@angular/core';

const SCHEME_PDF_PRIMARY = 'SCHEME-PDF-PRIMARY';
const SCHEME_PDF_SECONDARY = 'SCHEME-PDF-SECONDARY';

export interface SchemePdf {
    primary: string[];
    secondary: string[];
}

export interface EnvironmentDiff {
    added: {
        'SCHEME-PDF'?: string;
        [SCHEME_PDF_PRIMARY]?: string;
        [SCHEME_PDF_SECONDARY]?: string;
    };
    removed: {};
    modified: {
        'SCHEME-PDF'?: EnvironmentDiffModified;
        [SCHEME_PDF_PRIMARY]?: EnvironmentDiffModified;
        [SCHEME_PDF_SECONDARY]?: EnvironmentDiffModified;
    };
}

export interface EnvironmentDiffModified {
    removed?: string[];
    added?: { value: string }[];
}

@Injectable({
    providedIn: 'root',
})
export class SchemeService {
    public getCurrentSchemes(environmentDiff: EnvironmentDiff): SchemePdf {
        if (environmentDiff?.added[SCHEME_PDF_PRIMARY] || environmentDiff?.added[SCHEME_PDF_SECONDARY]) {
            const primary = (environmentDiff.added[SCHEME_PDF_PRIMARY] || '').split(';');
            const secondary = (environmentDiff.added[SCHEME_PDF_SECONDARY] || '').split(';');
            return { primary, secondary };
        }
        if (environmentDiff?.modified[SCHEME_PDF_PRIMARY] || environmentDiff?.modified[SCHEME_PDF_SECONDARY]) {
            const primary = this.getModified(environmentDiff.modified[SCHEME_PDF_PRIMARY]);
            const secondary = this.getModified(environmentDiff.modified[SCHEME_PDF_SECONDARY]);
            return { primary, secondary };
        }
        return undefined;
    }

    private getModified(modified: EnvironmentDiffModified) {
        if (modified?.added?.length) {
            return modified.added.map((added) => added.value.split(';')).reduce((prev, curr) => [...prev, ...curr], []);
        }
        if (modified?.removed?.length) {
            return [];
        }
        return undefined;
    }
}
