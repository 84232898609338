import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomerSearchService } from 'src/app/_shared/services/customer-search.service';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { CustomerSelection } from '../customer-selection/customer-selection.component';

@Component({
    selector: 'app-customer-search-dialog',
    templateUrl: './customer-search-dialog.component.html',
    styleUrls: ['./customer-search-dialog.component.scss'],
})
export class CustomerSearchDialogComponent {
    public isLoading = false;
    public searchCustomerValue = '';
    public customerSearchResult: any[];

    constructor(
        private customerSearchService: CustomerSearchService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private selectedCustomerService: SelectedCustomerService,
        public dialogRef: MatDialogRef<CustomerSearchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { partnerMode: boolean }
    ) {}

    public onCustomerSearch() {
        this.searchCustomerValue = this.searchCustomerValue.trim();

        if (!this.searchCustomerValue) {
            return;
        }

        this.isLoading = true;
        this.customerSearchResult = null;
        this.customerSearchService.findCustomers(this.searchCustomerValue).subscribe(
            (res) => {
                this.isLoading = false;
                this.customerSearchResult = res;
            },
            () => {
                this.isLoading = false;
                this.customerSearchResult = null;
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('CONFIGURATION.CUSTOMER_SEARCH.FAILURE_MSG'),
                    isFailure: true,
                });
            }
        );
    }

    public selectCustomer(customer: CustomerSelection) {
        const subject = this.data.partnerMode
            ? this.selectedCustomerService.customerForPartnerMode$
            : this.selectedCustomerService.selectedCustomer$;
        subject.next(customer);
        this.dialogRef.close(true);
    }

    public closeDialog() {
        this.dialogRef.close(false);
    }
}
