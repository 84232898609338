import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigitApiService } from './configit-api.service';
import { ConfigitQuestAdapterService } from './configit-quest-adapter.service';
import { ConflictingValueDialogComponent } from './conflicting-value-dialog.component';
import { PackerConfigitQuestAdapterService } from './packer-configit-quest-adapter.service';

@NgModule({
    imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule],
    declarations: [ConflictingValueDialogComponent],
    providers: [ConfigitApiService, ConfigitQuestAdapterService, PackerConfigitQuestAdapterService],
})
export class ConfigitQuestAdapterModule {}
