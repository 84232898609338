import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestCheckResult, QuestionType, QuestService, QuestTransform, QuestValueChange } from '@vi/quest';
import { QuestPartReset } from '@vi/quest/lib/quest-part-reset.interface';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ConfigitApiService } from './configit-api.service';
import { ConfigitQuestModelTransform } from './configit-quest-model-transform';
import {
    ConfigitAssignment,
    ConfigitConfiguration,
    ConfigitConfigurationAndProducts,
    ConfigitItem,
    ConfigitModelOriginal,
    ConfigitQuestSettings,
    ConfigitTemplate,
    ConfigitValue,
    ConflictItem,
} from './configit-types.interface';
import { ConfigitUtils as utils } from './configit.utils';
import { ConflictingValueDialogComponent } from './conflicting-value-dialog.component';

@Injectable()
export class ConfigitQuestAdapterService implements QuestService {
    public transformer: QuestTransform;
    // amount of conflicting items to display to the user (on conflicting change)
    public maxConflictingItems = 3;

    constructor(protected api: ConfigitApiService, protected translate: TranslateService, protected dialog: MatDialog) {
        this.transformer = new ConfigitQuestModelTransform(this.config(), translate);
    }

    public config(): ConfigitQuestSettings {
        return environment.quest;
    }

    /**
     * Gets quest model
     *
     * @param name Material name
     * @param preselect Param to be sent as preselect env property
     * @param order Param to be sent as order env property
     * @param assignments Preset assignments
     */
    public get(name: string, preselect?: string, order?: string, assignments?: any): Observable<Quest> {
        const params = {
            material: name,
            preselect,
            order,
        };

        return forkJoin([this.api.getTemplate(params), this.api.getConfiguration(params, assignments)]).pipe(
            map((resp: any[]) => ({ template: resp[0], configuration: resp[1] })),
            map(
                (model: ConfigitModelOriginal) =>
                    this.addAssignments(model, model.configuration.newAssignments) && model
            ),
            map((model: ConfigitModelOriginal) => this.transformer.transform(model))
        );
    }

    /**
     * Checks changed value
     *
     * @param changed Value
     * @param order Optional order
     */
    public check(changed: QuestValueChange, order?: string): Observable<QuestCheckResult> {
        const original: ConfigitModelOriginal = changed.quest.original;
        const name = original.template.name;
        // send only user assigned and default as existing assignments
        const assignments = (original.assignments || []).filter((item) => item.isUserAssignment);
        // as configit throws error on undefined values
        const value = this.prepareValue(changed);
        // additionals
        const additional = { order };

        return this.api.setAssignment(name, changed.id, value, assignments, additional).pipe(
            concatMap((resp: ConfigitConfiguration) => this.checkConflicts(resp, original)),
            map((resp: ConfigitConfiguration) => this.removeAssignments(original, resp.assignmentsToRemove) && resp),
            map((resp: ConfigitConfiguration) => this.addAssignments(original, resp.newAssignments) && resp),
            map((resp: ConfigitConfiguration) => this.mergeConfigurations(original, resp)),
            map((conf: ConfigitConfiguration) => this.transformer.transform(conf)),
            map((transformed: Quest) => ({
                model: transformed,
                errors: this.errors(),
            }))
        );
    }

    public reset(reset: QuestPartReset) {
        const original: ConfigitModelOriginal = reset.quest.original;
        const name = original.template.name;
        // send only user assigned without those of part that is reset
        const assignments = (original.assignments || []).filter(
            (item) => item.isUserAssignment && !item.variableName.startsWith(reset.part.id)
        );

        const params = {
            material: name,
        };

        return forkJoin([this.api.getTemplate(params), this.api.getConfiguration(params, assignments)]).pipe(
            map(([template, conf]: [ConfigitTemplate, ConfigitConfigurationAndProducts]) => {
                return {
                    template,
                    configuration: conf.configuration,
                    bomItems: conf.bomItems,
                };
            }),
            map(
                (model: ConfigitModelOriginal) =>
                    this.addAssignments(model, model.configuration.newAssignments) && model
            ),
            map((model: ConfigitModelOriginal) => this.transformer.transform(model))
        );
    }

    /**
     * Submits model data
     */
    public submit(model: Quest, order?: string, recommend?: boolean): Observable<QuestCheckResult> {
        const original: ConfigitModelOriginal = model.original;
        return this.api
            .submitConfiguration(original.template.name, original.assignments, order, model.captcha, recommend)
            .pipe(
                map((resp: ConfigitConfiguration) => this.mergeConfigurations(original, resp)),
                map((result) => this.transformer.transform(result)),
                map((transformed: Quest) => ({
                    model: transformed,
                    errors: this.errors(),
                }))
            );
    }

    public getEnergyLabelFromConfiguration(model: Quest): Observable<string> {
        const original: ConfigitModelOriginal = model.original;
        return this.api.getEnergyLabelFromConfiguration(original.template.name, original.assignments);
    }

    /**
     * Checks if conflict is returned on update configuration and prompts the user
     */
    protected checkConflicts(
        updated: ConfigitConfiguration,
        original: ConfigitModelOriginal
    ): Observable<ConfigitConfiguration> {
        if (updated.conflict) {
            // prepare conflicting items and dialog
            const items = this.conflictingItems(updated, original);
            const prompt = this.dialog.open(ConflictingValueDialogComponent, {
                data: items,
                disableClose: true,
            });

            return prompt.afterClosed().pipe(
                // and revert if not agreed to continue
                map((agree) => (agree && updated) || this.revertChanges(updated))
            );
        } else {
            // proceed otherwise
            return of(updated);
        }
    }

    /**
     * Extracts conflicting values basis on conflict info
     */
    protected conflictingItems(
        configitConfiguration: ConfigitConfiguration,
        original: ConfigitModelOriginal
    ): ConflictItem[] {
        // extract conflicting assignment names
        const conflictingNames = configitConfiguration.conflict.conflictingAssignments;

        // extract conflicts to show
        const conflictingItems = configitConfiguration.assignmentsToRemove
            .filter((assignment) => {
                return configitConfiguration.variableStates
                    .filter((question) => {
                        return (
                            question.show &&
                            conflictingNames.find((conflictingName) => conflictingName === question.fullyQualifiedName)
                        );
                    })
                    .map((question) => question.fullyQualifiedName)
                    .find((id) => id === assignment.variableName);
            })
            .map((conflict) => {
                // Extract translated question text and unit of conflicting assignment from original model
                const conflictingQuestion = original.template.variables.find(
                    (question) => question.fullyQualifiedName === conflict.variableName
                );

                return {
                    conflictingText: conflictingQuestion?.text,
                    conflictingValue: conflict?.valueText,
                    conflictingUnit: conflictingQuestion?.customProperties?.Unit,
                };
            });

        return conflictingItems.slice(0, this.maxConflictingItems);
    }

    /**
     * Reverts changes done in configuration on update
     */
    protected revertChanges(updated: ConfigitConfiguration): ConfigitConfiguration {
        updated.assignmentsToRemove = [];
        updated.newAssignments = [];
        updated.uiGroupStates = [];
        updated.variableStates = [];

        return updated;
    }

    /**
     * Prepares value for configit (empty string for none) and/or stringified for file
     */
    protected prepareValue(changed: QuestValueChange): any {
        if (changed.question.type === QuestionType.file) {
            return JSON.stringify(changed.value);
        }
        return changed ? changed.value : '';
    }

    /**
     * Adds new assignments to original model assignments
     *
     * @param original Original model
     * @param added Added assignments
     */
    protected addAssignments(original: ConfigitModelOriginal, added: ConfigitAssignment[]): ConfigitModelOriginal {
        if (added) {
            original.assignments = [...(original.assignments || []), ...added];
        }

        return original;
    }

    /**
     * Removes assignments from original model assignments
     *
     * @param original Original model
     * @param remove Removed assignments
     */
    protected removeAssignments(original: ConfigitModelOriginal, remove: ConfigitAssignment[]): ConfigitModelOriginal {
        if (remove?.length) {
            const keys = utils.objectize(remove, 'variableName');
            const removed = (item: ConfigitAssignment) => !keys[item.variableName];

            original.assignments = (original.assignments || []).filter(removed);
        }

        return original;
    }

    /**
     * Merges original configuration within updated one
     *
     * @param original Original model
     * @param updated Updated configuaration
     * @returns Changed original
     */
    protected mergeConfigurations(original: ConfigitModelOriginal, updated: ConfigitConfiguration): any {
        const unique = 'fullyQualifiedName';
        const states = [...updated.variableStates, ...updated.uiGroupStates];
        const update = utils.objectize(states, unique);

        ['variableStates', 'uiGroupStates'].forEach((part) => {
            original.configuration[part].forEach((variable: ConfigitItem, idx: number) => {
                const next: ConfigitItem = update[variable[unique]];

                if (next) {
                    if (next.valueStates) {
                        // merge values and assign to original configuration
                        const values = this.mergeValues(variable.valueStates, next.valueStates);
                        original.configuration[part][idx] = {
                            ...next,
                            valueStates: values,
                        };
                    } else if (next.invalidMessage) {
                        // update only message as previous value could be unknown (see checkErrors on ConfigitApiService)
                        original.configuration[part][idx].invalidMessage = this.translate.instant(
                            `${this.config().context}.${next.invalidMessage}`
                        );
                        original.configuration[part][idx].invalidValue = next.invalidValue;
                        original.configuration[part][idx].valid = false;
                    } else {
                        // just update
                        original.configuration[part][idx] = next;
                    }
                } else if (variable.invalidValue !== undefined) {
                    // if no next (which can clear previous invalid) unset invalid
                    original.configuration[part][idx].invalidMessage = undefined;
                    original.configuration[part][idx].invalidValue = undefined;
                }
            });
        });

        // bdza: Merging environmentDiff.modified to get modified.CATEGORY-BDIS info
        if (original.configuration.environmentDiff && updated.environmentDiff) {
            original.configuration.environmentDiff.modified = updated.environmentDiff.modified;
        }

        // JrkC: also with the adding stuff?
        if (original.configuration.environmentDiff && updated.environmentDiff) {
            original.configuration.environmentDiff.added = updated.environmentDiff.added;
        }

        // chjm: what about the removed ones?
        if (original.configuration.environmentDiff && updated.environmentDiff) {
            original.configuration.environmentDiff.removed = updated.environmentDiff.removed;
        }

        return original;
    }

    /**
     * Merges values of original and updated after set assignment
     */
    protected mergeValues(original: ConfigitValue[], updated: ConfigitValue[]): any {
        // map name driven
        const unique = 'name';
        const prev = utils.objectify(original, unique);
        const curr = utils.objectify(updated, unique);
        // then replace only changed
        curr.forEach((value, key) => {
            prev.set(key, value);
        });

        return [...prev.values()].filter(Boolean);
    }

    protected errors(): boolean {
        return false;
    }
}
