import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface Project {
    error?: string;
    status: string;
    salesOrg: string;
    salesDistrict: string;
    projectNo: string;
    description1?: string;
    projectPartners?: any[];
}

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    constructor(private http: HttpClient) {}

    public getProject(projectNumber: string) {
        return this.http.get<Project>(`${environment.http.baseUrl}projects/${projectNumber.padStart(10, '0')}`);
    }
}
