<app-card *ngIf="part">
    <div header>
        <span class="title"> {{ part.title }}</span>
    </div>
    <div body>
        <div fxLayout="column">
            <div *ngFor="let question of part.questions" class="question {{ createClassName(question.id) }}">
                <div fxLayout="row" fxLayoutAlign="space-between flex-end">
                    <div class="label" [innerHTML]="question.text"></div>
                    <div class="value">{{ question.value }} {{ question.unit }}</div>
                </div>
                <mat-error *ngIf="question.error">{{ question.error }}</mat-error>
            </div>
        </div>
    </div>
</app-card>
