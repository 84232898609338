<mat-spinner [diameter]="24" *ngIf="loading"></mat-spinner>
<a mat-icon-button class="error" *ngIf="hasError">
    <mat-icon>cancel</mat-icon>
</a>

<ng-container *ngIf="complete">
    <a
        mat-icon-button
        [href]="url(true)"
        [style.visibility]="download ? 'visible' : 'hidden'"
        [matTooltip]="'NEXT_STEPS.TOOLTIP.DOWNLOAD' | translate"
    >
        <mat-icon>file_download</mat-icon>
    </a>
    <a
        mat-icon-button
        target="_blank"
        [href]="url()"
        [style.visibility]="inline ? 'visible' : 'hidden'"
        [matTooltip]="'NEXT_STEPS.TOOLTIP.OPEN_IN_NEW' | translate"
    >
        <mat-icon>open_in_new</mat-icon>
    </a>
</ng-container>
